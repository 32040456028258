<template>
    <div class="background-white">
        <div class="form-profile-body">
            <div v-if="datatransaksi.length > 1">
                <button class="btn btn-danger" @click="openmodalform">Tambah Komplain</button>
            </div>
            <div v-else-if="datatransaksi.length <= 0">
            </div>
            <div v-else-if="datatransaksi">
                <button class="btn btn-danger" @click="openmodalform">Tambah Komplain</button>
            </div>
        </div>
        
        <div v-if="loadingSubmit" class="loading-submit-animation-container" >
            <div class="loading-submit-animation-content">
                <div>
                    <div class="lds-facebook"><div></div><div></div><div></div></div>
                    <p>Mohon tunggu, data sedang diproses</p>
                </div>
            </div>
        </div>

        <Modal
          :show="modal.showModal"
          :header="modal.header"
          :headerBorder="modal.headerBorder"
          :title="modal.title"
          :titleText="modal.titleText"
          :footer="modal.footer"
          :footerBtnPosition="modal.footerBtnPosition"
          :footerBorder="modal.footerBorder"
          :btnCancel="modal.btnCancel"
          :btnSubmit="modal.btnSubmit"
          :btnSubmitText="modal.btnSubmitText"
          :btnCancelText="modal.btnCancelText"
          :btnSubmitWidth="modal.btnSubmitWidth"
          :btnCancelWidth="modal.btnCancelWidth"
          @closeModal="closeModal"
          @submitModal="submitModal"
        >
          <!-- Notif add cart -->
          <div v-if="modalTipe === 'komplainsuccess'">
            <div class="text-center mt-2 border-2 border-light border-bottom">
              <i class='bx bxs-check-circle ' style="font-size:115px; color:var(--primary-color)"></i>
              <div class="notif-text">
                <h5>Komplain Anda Berhasil dikirim</h5>
              </div>
            </div>
            <div class="text-right mt-2">
                <button @click="dataoke" class="btn btn-red">Oke</button>
            </div>
          </div>

          <!-- Info Negosiasi -->
          <div v-else-if="modalTipe === 'errorimagesize'" class="d-flex align-items-center justify-content-center text-center mt-2">
            <div>
              <i class='bx bxs-error-circle' style="font-size:115px; color: #E40C0C;"></i>
              <h5>Ooppsss</h5>
              <p>Ukuran format Gambar anda lebih dari 2mb</p>
            </div>
          </div>
          <div v-else-if="modalTipe === 'errorvideosize'" class="d-flex align-items-center justify-content-center text-center mt-2">
            <div>
              <i class='bx bxs-error-circle' style="font-size:115px; color: #E40C0C;"></i>
              <h5>Ooppsss</h5>
              <p>Ukuran format video anda lebih dari 50mb</p>
            </div>
          </div>
          <div v-else-if="modalTipe === 'errorformat'" class="d-flex align-items-center justify-content-center text-center mt-2">
            <div>
              <i class='bx bxs-error-circle' style="font-size:115px; color: #E40C0C;"></i>
              <h5>Ooppsss</h5>
              <p>Format File Image Hanya berupa(.jpg, .png, .jpeg, .webp)</p>
            </div>
          </div>
          <div v-else-if="modalTipe === 'errorinput'" class="d-flex align-items-center justify-content-center text-center mt-2">
            <div>
              <i class='bx bxs-error-circle' style="font-size:115px; color: #E40C0C;"></i>
              <h5>Ooppsss</h5>
              <p>{{massageadmin}}</p>
            </div>
          </div>
        </Modal>




        <!-- modal form -->
            <div v-if="modalform" class="modal fade show bd-example-modal-lg" aria-modal="true" style="display: block; background-color: rgba(0,0,0,0.3);" tabindex="-1" role="dialog" id="modalchangepassword" >
                <!-- <div class="modal" tabindex="-1" role="dialog" id="modalchangepassword" > -->
                <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Tambah Komplain</h5>
                            <button type="button" class="close" @click="closemodalform" style="background: none;">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body form-nego-data">
                            <form @submit.prevent="addComplain">
                                <div class="form-profile-body">
                                        <div>
                                            <label for="" class="mt-3">Produk</label>
                                            <div  class="container-fluid px-lg-3 px-1" style="margin:0px;">
                                                <div v-if="datatransaksi.length <= 0" class="row">
                                                    <p style="width:100%; text-align:center;">Mohon Maaf tidak terdapat produk yang dapat di ulas pada ID pesanan diatas</p>
                                                </div>
                                                <div v-else-if="datatransaksi" style="padding:0px" class="row">
                                                    <div v-for="(produk, index) in datatransaksi" :key="index" class="col-md-6 p-lg-3 mt-2">
                                                        <table class="data-tabel mt-2" :class="{ 'console_log': produk.id == detail_id }" @click="setId(produk.id)">
                                                            <tr>
                                                                <td class="td-nama-produk">
                                                                        <div class="nama-produk">
                                                                            <figure v-bind:style="{ backgroundImage: 'url(' +$imgProductUrl+ produk.image + ')' }" class="img-profile">
                                                                                <!-- <img :src="image_produk" alt=""> -->
                                                                            </figure>
                                                                            <h6 class="px-2">{{produk.name_product}}</h6>
                                                                        </div>
                                                                    </td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div v-else-if="datatransaksi.length == 1" class="row">
                                                    <div v-for="(produk, index) in datatransaksi.transaction_detail" :key="index" class="col-md-6 p-md-3 mt-5">
                                                        <table class="data-tabel mt-2" :class="{ 'console_log': produk.id == detail_id }" @click="setId(produk.id)">
                                                            <tr>
                                                                <td class="td-nama-produk">
                                                                        <div class="nama-produk">
                                                                            <figure v-bind:style="{ backgroundImage: 'url(' +$imgProductUrl+ produk.image + ')' }" class="img-profile">
                                                                                <!-- <img :src="image_produk" alt=""> -->
                                                                            </figure>
                                                                            <h6 class="px-2">{{produk.name_product}}</h6>
                                                                        </div>
                                                                    </td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div v-else-if="datatransaksi.length > 1" class="row">
                                                    <div v-for="(produk, index) in datatransaksi" :key="index" class="col-md-6 p-md-3 mt-5">
                                                        <table class="data-tabel mt-2" :class="{ 'console_log': produk.id == detail_id }" @click="setId(produk.id)">
                                                            <tr>
                                                                <td class="td-nama-produk">
                                                                        <div class="nama-produk">
                                                                            <figure v-bind:style="{ backgroundImage: 'url(' +$imgProductUrl+ produk.image + ')' }" class="img-profile">
                                                                                <!-- <img :src="image_produk" alt=""> -->
                                                                            </figure>
                                                                            <h6 class="px-2">{{produk.name_product}}</h6>
                                                                        </div>
                                                                    </td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div class="w-100" v-else>
                                                    <p style="width:100%; text-align:center;">Mohon Maaf tidak terdapat produk yang dapat di ulas pada ID pesanan diatas</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-5"> 
                                            <small class="error text-danger mt-5" v-if="!alertprodukid">Produk Wajib Dipilih</small>
                                        </div>
                                        <div class="form-group mt-5">
                                            <label for="keterangan">Keterangan Komplain</label>
                                            <textarea class="form-control mt-2" v-model="keterangan" :class="{ 'border-danger': v$.keterangan.$error }" name="keterangan" id="keterangan" rows="3"></textarea>
                                            <small
                                                class="error text-danger"
                                                v-for="(error, index) of v$.keterangan.$errors"
                                                :key="index"
                                                >
                                                {{ error.$message }}
                                            </small>
                                        </div>
                                        <div class="w-100 px-3 py-4 card mt-3">
                                            <div class="">
                                                <div @click="addInput()" class="btn btn-danger float-right">
                                                    Tambah Bukti
                                                </div>
                                            </div>
                                        </div>
                                        <div class="w-100">
                                            <div v-for="(bukti, index) in databukti" :key="index">
                                                <label class="mt-3" for="bukti">Upload Bukti Komplain {{index +1}}</label><br>
                                                <div style="display:flex;">
                                                    <label :for="`file${index}`" class="input-group flex-nowrap"> 
                                                        <label style="background-color:#e9ecef;color:#000;" :for="`file${index}`" type="button" class="btn btn-outline-secondary label-file" >Choose File</label>
                                                        <div class="d-flex align-items-center border border-secondary p-2 w-100" style="overflow-x: hidden;">
                                                            <p class="mb-0">{{ bukti.fileName }}</p>
                                                        </div>
                                                    </label>
                                                    <input :id="`file`+index" type="file" @change="inputfile($event,index)" class="d-none form-control mt-2" name="data" accept=".jpg,.jpeg,.png,.webp,.mp4,.mpeg,.mov,.3gp,.mkv,.avi" placeholder="Upload Bukti Komplain"  />
                                                    <div class="mt-2 view-web" style="margin-left: 10px;">
                                                        <p class="bg-danger btn-delete text-white bridge" @click="deletefile(index)"><i class='bx bx-trash'></i></p>
                                                    </div>
                                                </div>
                                                <div class="mt-2 view-mobile">
                                                    <p class="bg-danger btn-delete text-white bridge" @click="deletefile(index)"><i class='bx bx-trash'></i></p>
                                                </div>
                                            </div>
                                        </div>
                                        <small
                                            class="error text-danger"
                                            v-for="(error, index) of v$.databukti.$errors"
                                            :key="index"
                                            >
                                            {{ error.$message }}
                                        </small>
                                        <small v-if="cekdatabukti"
                                            class="error text-danger"
                                            >
                                            Bukti wajib diisi
                                        </small>
                                        <div class="w-100">
                                            <!-- <button type="submit" class="btn btn-success mt-3">Ajukan Komplain</button> -->
                                        </div>
                                </div>
                                
                                <div class="modal-footer">
                                    <div type="button" class="btn btn-secondary" @click="closemodalform" data-bs-dismiss="modal">Batal</div>
                                    <button type="submit" class="btn btn-danger"><i class='bx bxs-save' ></i> Simpan</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        <!-- modal form -->
    </div>
</template>

<script>

import axios from 'axios'
import Modal from '../Modal.vue'
import useVuelidate from '@vuelidate/core'
import { required,helpers} from '@vuelidate/validators'

export default {
    props:['datatransaksi'],
    name: 'Add-Complain',
    data() {
        return {
        inputs: [],
        search:'paid',
        datakirim:{
            datacomplaint:{},
            datatransaksi:{}
        },
        keterangan:'',
        modalform:false,
        transaksi_id:'',
        detail_id:'',
        modalTipe:'',
        massageadmin:'',
        modal:{
            showModal: false,
            header:false,
            headerBorder: false,
            title: false,
            titleText: '',
            footer: false,
            footerBtnPosition:'',
            footerBorder: false,
            btnCancel: false,
            btnSubmit: false,
            btnSubmitText: '',
            btnCancelText: '',
            btnSubmitWidth: '',
            btnCancelWidth: '',
        },
        alertprodukid:true,
        databukti:[],
        cekdatabukti:false,
        loadingSubmit:false,
        'toastShowed': 'hide-before',
        loading:true,
        LoadingSkeleton:true,
        };
        
    },
    setup () {
        return { v$: useVuelidate() }
    },
    validations() {
        return {
                databukti: {
                    required: helpers.withMessage('Bukti wajib diisi', required),
                },
                keterangan: {
                    required: helpers.withMessage('Keterangan Komplain wajib diisi', required),
                },
        }
    },
    watch: {
        detail_id(){
            this.alertprodukid=true
        },
    },

   components:{
        Modal
    },
    created(){
    },
    computed: {
    },
    methods: {
        openmodalform(){
            this.modalform =true
        },
        closemodalform(){
            this.modalform =false
        },
        setId(value){
            this.detail_id = value
        },
        dataoke(){
            this.transaksi_id = this.$router.currentRoute._value.query.id_transaksi
            axios.get('/transaction/complaint/'+this.transaksi_id).then(res => {
                this.datakirim.datacomplaint  = res.data.data
                axios.get('/transaction/'+this.transaksi_id).then(res => {
                    this.datakirim.datatransaksi = res.data.data.complaint
                    this.$emit('datareload', this.datakirim)
                    this.setModal(false, this.modalTipe)
                        // this.loading=false
                })
                .catch(() =>{
                    // this.loading=false
                }) 
                    // this.loading=false
            })
            .catch(() =>{
                // this.loading=false
            }) 
        },
        deletefile(value){
            this.databukti.splice(value, 1)
            this.inputs.splice(value, 1)
        },
        addInput(){
            let template = {
                fileName: 'No file chosen',
                file: null
            }
            if(this.databukti.length == 0 || this.databukti[this.databukti.length - 1].file != null ){
                this.databukti.push(template)
            }
        },
        setModal(condition, tipe){
        this.resetDataModal()
        this.modal.showModal= condition
        condition == true ? this.modalTipe = tipe : ''
        if(tipe == 'komplainsuccess' && condition){
            this.modal.header=true
        }else if(tipe == 'errorimagesize' && condition){
            this.modal.header=true
            this.modal.footer= true
            this.modal.footerBtnPosition='right'
            this.modal.btnSubmit= true
            this.modal.btnSubmitText= 'Oke'
            this.modal.btnSubmitWidth='auto'
        }else if(tipe == 'errorvideosize' && condition){
            this.modal.header=true
            this.modal.footer= true
            this.modal.footerBtnPosition='right'
            this.modal.btnSubmit= true
            this.modal.btnSubmitText= 'Oke'
            this.modal.btnSubmitWidth='auto'
        }else if(tipe == 'errorformat' && condition){
            this.modal.header=true
            this.modal.footer= true
            this.modal.footerBtnPosition='right'
            this.modal.btnSubmit= true
            this.modal.btnSubmitText= 'Oke'
            this.modal.btnSubmitWidth='auto'
        }else if(tipe == 'errorinput' && condition){
            this.modal.header=true
            this.modal.footer= true
            this.modal.footerBtnPosition='right'
            this.modal.btnSubmit= true
            this.modal.btnSubmitText= 'Oke'
            this.modal.btnSubmitWidth='auto'
        }
        },
        resetDataModal(){
        this.modal.showModal = false
        this.modal.header = false,
        this.modal.headerBorder = false
        this.modal.title = false
        this.modal.titleText = '' 
        this.modal.footer = false
        this.modal.footerBtnPosition =''
        this.modal.footerBorder = false
        this.modal.btnCancel = false
        this.modal.btnSubmit = false
        this.modal.btnSubmitText = ''
        this.modal.btnCancelText = ''
        this.modal.btnSubmitWidth = ''
        this.modal.btnCancelWidth = ''
        },
        closeModal(){
        this.setModal(false, this.modalTipe)
        this.$store.dispatch('carts')
        },
        submitModal(){
        this.setModal(false, this.modalTipe)
        },
        setToast(param = null){
            if(param == null){
                this.toastShowed = 'show'
                setTimeout(() => this.toastShowed = 'hide-after', 5000)
            }else{
                this.toastShowed = param
            }
        },
        inputfile(event,index){
            let file = event.target.files[0];
            if (
            file["type"] === "image/jpeg" ||
            file["type"] === "image/jpg" ||
            file["type"] === "image/png" ||
            file["type"] === "image/webp" 
            ) {
                if(file["size"] <= 2048000){
                    this.databukti[index].file = event.target.files[0]
                    this.databukti[index].fileName = event.target.files[0].name
                }else{
                    document.getElementById(`file`+index).value = "";
                    this.setModal(true, 'errorimagesize')
                    // this.$swal("Ooopss", "Ukuran format Gambar anda lebih dari 2mb", "error", {
                    // button: "OK"})
                }
                
            }else if(
            file["type"] === "video/mp4" ||
            file["type"] === "video/*" ||
            file["type"] === "video/mpeg" ||
            file["type"] === "video/mov" ||
            file["type"] === "video/3gp" ||
            file["type"] === "video/mkv" ||
            file["type"] === "video/avi" ){
                if(file["size"] <= 51200000){
                    this.databukti[index].file = event.target.files[0]
                    this.databukti[index].fileName = event.target.files[0].name
                }else{
                    document.getElementById(`file`+index).value = "";
                    this.setModal(true, 'errorvideosize')
                }

            } else {
                document.getElementById(`file`+index).value = "";
                this.setModal(true, 'errorformat')
            }
            
        },
        addComplain(){
            this.v$.$touch()
            if(!this.v$.$invalid){
               this.cekdatabukti =true 
            }
            let k = 0
            for(let i = 0;i<=this.databukti.length;i++){
                if(this.databukti[k].file != null){
                    this.cekdatabukti =false
                    break
                }else{
                    this.cekdatabukti =true
                }
                k++
            }
            if (!this.v$.$invalid && this.detail_id && !this.cekdatabukti) {
                let data = new FormData();
                data.append("transaction_detail_id", this.detail_id);
                data.append("description_detail", this.keterangan);
                this.databukti.forEach((item)=>{
                    data.append("image[]", item.file)
                })
                this.loadingSubmit = true
                
                axios.post(`/transaction/complaint`, data)
                .then(() => {
                    this.loadingSubmit = false
                    this.setModal(true, 'komplainsuccess')
                    this.modalform =false 
                    

                })
                .catch((e) => {
                    // this.$swal("Ooops", e.response.data.message, "error", {
                    //     button: "OK"})
                    this.loadingSubmit = false
                    if(e.response.data.message == 'You already make complaint with this transaction'){
                        this.massageadmin = 'Anda Sudah melakukan komplain pada produk didalam invoice ini'
                    }else{
                        this.massageadmin = e.response.data.message
                    }
                    this.setModal(true, 'errorinput')
                    
                });
            }else if(!this.detail_id){
                this.alertprodukid = false
            }
        }
    }
}
</script>

<style scoped>

.btn-delete{
    padding: 0.385rem 0.5rem;
    border-radius: 5px;
    display: initial;
}
.img-profile{
    width: 150px;
    height: 110px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.img-profile img{
    width: 100%;
}
.td-nama-produk{
    max-width: 200px !important;
}
.nama-produk{
    display: flex;
    margin: 0px;
    margin-right: 0px !important;
}
.nama-produk h6{
    vertical-align: inherit;
    margin: auto 0;
}
.console_log{
    border: 2px solid red !important;
}
.label-file{
    width: 175px;
}
.view-web{
    display: none;
}
.view-mobile{
    display: block;
}

@media (min-width: 319px) {
}

@media (min-width: 374px) {
}

@media (min-width: 424px) {
}

@media (min-width: 767px) {
    .label-file{
        width: 140px;
    }
    .view-web{
        display: block;
    }
    .view-mobile{
        display: none;
    }
  
}

@media (min-width: 1023px) {
    .label-file{
        width: 130px;
    }
}

@media (min-width: 1439px) {
    .label-file{
        width: 125px;
    }
}

@media (min-width: 2000px) {
}
</style>
