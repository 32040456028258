<template>
    <div class="banner section section-modif">
        <div class="banner-container container" >
            <div  class="banner-loading" v-if="loadingMainBanner === true" ></div> 
            <swiper
            v-else
                :slidesPerView="1"
                :spaceBetween="30"
                :loop="true"
                :autoplay="autoplay"
                :pagination="{
                clickable: true,
                }"
      
                :navigation="true"
                :modules="modules"
                class="banner-swiper mySwiper"
            >
                <swiper-slide v-for="(slide, i) in sliders" :key="i">
                    <!-- <img :src="require(`../assets/img/Group 1.png`)" alt=""> -->

                    <!-- <img  class="d-block w-100" :src="baseUrl+'/storage/'+slide" alt=""> -->
                    <a :href="(slide.url.substring(0,4) === 'http' ? '' : 'https://')+slide.url" v-if="slide.url !== '' || slide.url !== null" target="_blank">
                        <img  class="d-block w-100" :src="baseUrl+'/storage/'+slide.image" alt="">
                    </a>
                    <img v-else class="d-block w-100" :src="baseUrl+'/storage/'+slide.image" alt="">
                </swiper-slide>
            </swiper>
            <div class="banner-categories" v-if="dataCategories.length > 0">
                <div class="product-header mb-0">
                    <h3 class="title">Kategori Produk</h3>
                </div>
                <div class="bg-white rounded">
                    <swiper
                    :slidesPerView="1"
                    :centeredSlides="false"
                    :slidesPerGroupSkip="1"
                    :grabCursor="true"
                    :keyboard="{
                        enabled: true,
                    }"
                    :breakpoints="{
                        '325': {
                        slidesPerView: 2,
                        slidesPerGroup: 2,
                        },
                        '425': {
                        slidesPerView: 3,
                        slidesPerGroup: 2,
                        },
                        '576': {
                        slidesPerView: 4,
                        slidesPerGroup: 2,
                        },
                        '769': {
                        slidesPerView: 5,
                        slidesPerGroup: 2,
                        },
                        '848': {
                        slidesPerView: 8,
                        slidesPerGroup: 2,
                        },
                        '992': {
                        slidesPerView: dataCategories.length,
                        slidesPerGroup: 2,
                        },
                    }"
                    :navigation="true"
                    :modules="modules"
                    class="categories"
                    v-if="dataCategories.length <10"
                    >
                    <swiper-slide v-for="(category, index) in dataCategories" :key="category" :index="index" class="categories-card shadow-sm rounded-3">
                        <router-link :to="{ path: '/produk', query: { kategori: category.id }}" data-bs-toggle="tooltip" data-bs-placement="top" :title="category.name">
                            <div class="categories-img img_less_ten">
                                <div class="categories-img-container">
                                    <div class="round-container"
                                    :style="{
                                        backgroundImage: 'url('+baseUrl+category.image+')'
                                    }" ></div>
                                    <!-- <img :src="baseUrl+category.image" class="rounded-circle"> -->
                                </div>
                            </div>
                            <div class="categories-content">
                                <p class="categories-title">{{category.name}}</p>
                            </div>
                        </router-link>
                    </swiper-slide>
                    </swiper>
                    <!-- Categories kalo lebih dari 10 data -->
                    <swiper
                    :slidesPerView="1"
                    :centeredSlides="false"
                    :slidesPerGroupSkip="1"
                    :grabCursor="true"
                    :keyboard="{
                        enabled: true,
                    }"
                    :breakpoints="{
                        '325': {
                        slidesPerView: 2,
                        slidesPerGroup: 2,
                        },
                        '425': {
                        slidesPerView: 3,
                        slidesPerGroup: 2,
                        },
                        '576': {
                        slidesPerView: 4,
                        slidesPerGroup: 2,
                        },
                        '769': {
                        slidesPerView: 5,
                        slidesPerGroup: 2,
                        },
                        '848': {
                        slidesPerView: 8,
                        slidesPerGroup: 2,
                        },
                        '992': {
                        slidesPerView: 10,
                        slidesPerGroup: 2,
                        },
                    }"
                    :navigation="true"
                    :modules="modules"
                    class="categories"
                    v-else
                    >
                    <swiper-slide v-for="(category, index) in dataCategories" :key="category" :index="index" class="categories-card shadow-sm rounded-3">
                        <router-link :to="{ path: '/produk', query: { kategori: category.id }}" data-bs-toggle="tooltip" data-bs-placement="top" :title="category.name">
                            <div class="categories-img">
                                <div class="categories-img-container">
                                    <div class="round-container"
                                    :style="{
                                        backgroundImage: 'url('+baseUrl+category.image+')'
                                    }" ></div>
                                    <!-- <img :src="baseUrl+category.image" class="rounded-circle"> -->
                                </div>
                            </div>
                            <div class="categories-content">
                                <p class="categories-title">{{category.name}}</p>
                            </div>
                        </router-link>
                    </swiper-slide>
                    </swiper>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import "swiper/css";

import "swiper/css/scrollbar";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { Autoplay, Keyboard, Scrollbar, Navigation, Pagination } from "swiper";

export default {
    name: 'banner-component',
    props:['dataCategories','banners'],
    setup() {
        return {
            modules: [Autoplay,Keyboard, Scrollbar, Navigation, Pagination],
        };
    },
    data(){
        return {
            sliders: [],
            baseUrl:process.env.VUE_APP_NAME_AXIOS,
            loadingMainBanner:true,
            autoplay: {
                delay: 4000,
                disableOnInteraction: false
            },
        }
    },

    components : {
        Swiper,
        SwiperSlide,
    },
    mounted(){
        // console.log('Banner: ', this.dataCategories);
    },
    created(){
        this.setSlider()
    },
    methods: {
        setSlider(){
            if(this.banners != null){
                for(let i = 0; i < Object.keys(this.banners).length; i++){
                    if(this.banners[i].position === 'Slider'){
                        this.sliders.push(this.banners[i])
                    }
                }
                this.loadingMainBanner = false
            }
        }
    },
    watch:{
        banners:{
            deep: true,
            handler: function(){
                this.setSlider()
                //           :autoplay='{
                // "delay": 2500,
                // "disableOnInteraction": false
                // }'
            }
        },
    }

    
}
</script>