<template>
<div class="form-profile-body py-3">
    <div class="btn-add mb-3">
      <router-link to="/profil/tambah-alamat" class="btn btn-red"><i class='bx bx-plus-circle'></i> Alamat</router-link>
    </div>
     <div class="">
            <!-- <pre>{{filteredData}}</pre> -->
            <!-- <pre>{{datalist}}</pre> -->
            <div class="header-table">
                <div class="header-table-left pt-1 pt-lg-3">
                  <label for="select_leght">
                    <span>Show </span>
                    <select
                      name="entries_data"
                      id="entries_data"
                      class="entries_select border rounded p-1"
                      v-model="jmlh_value"
                      v-on:change="entries_data(1), btn_paginate()"
                    >
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>

                    <span> entries</span>
                  </label>
                </div>
                <div class="header-table-right">
                  <div class="input-group search-group mb-3">
                    <input
                      v-model="search"
                      type="text"
                      class="form-control"
                      placeholder="Search"
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                    />
                    <div class="input-group-append tombol-search">
                      <span class="input-group-text h-100" id="basic-addon2"
                        ><i class="bx bx-search"></i
                      ></span>
                    </div>
                  </div>
                </div>
              </div>
            <div class="data-table">
              <table v-if="loading">
                <tr>
                  <th>No</th>
                  <th>Satker</th>
                  <th>Alamat</th>
                  <th>Aksi</th>
                </tr>
                <tbody>
                    <tr>
                        <td colspan="4">
                            <div class="d-flex justify-content-center">
                                <div class="lds-facebook"><div></div><div></div><div></div></div>
                            </div>
                        </td>
                    </tr> 
                </tbody>
              </table>
              <table v-else-if="!loading">
                <tr>
                  <th>No</th>
                  <th>Satker</th>
                  <th>Alamat</th>
                  <th>Aksi</th>
                </tr>
                <tbody v-if="!filteredData">
                    <tr>
                        <td colspan="4" style="text-align: center;">Data tidak ditemukan</td>
                    </tr> 
                </tbody>
                <tbody v-else-if="filteredData.length == 0">
                    <tr>
                        <td colspan="4" style="text-align: center;">Data tidak ditemukan</td>
                    </tr> 
                </tbody>
                <tbody v-else-if="filteredData.length <= 0">
                    <tr>
                        <td colspan="4" style="text-align: center;">Data tidak ditemukan</td>
                    </tr> 
                </tbody>
                <tbody v-else-if="filteredData.length > 0">
                  <tr v-for="(datasub, index) in filteredData" :key="datasub.id">
                    <td class="">
                      {{ index + 1 + jmlh_value * curentpage }}
                    </td>
                    <td class="">
                      {{ datasub.name }}
                    </td>
                    <td>
                      {{ datasub.customer_address?.address }}
                    </td>
                    <td>
                       <div class="d-flex">
                            <a class="btn btn-sm btn-red mx-2" @click="showModaldetail(datasub)" data-bs-toggle="modal" data-bs-target="#modaldetail"><i class='bx bx-detail'></i> Detail</a>
                            <router-link v-bind:to="'/profil/edit-alamat?id_alamat='+datasub.id" class="btn btn-sm btn-warning"><i class='bx bx-edit'></i> Edit</router-link>
                            <a class="btn btn-sm btn-danger mx-2" @click="showModal(datasub.id)" data-bs-toggle="modal" data-bs-target="#modalhapus"><i class='bx bxs-message-square-x'></i> Hapus</a>
                        </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div style="display-flex">
              <button class="btn btn-outline-secondary p-1 px-2 rounded-0" @click="btnpage(-1)">
                <i class="bx bx-chevron-left"></i>
              </button>
              <button v-if="(curentpage+1) ==initPagination && (curentpage-1) > 0" class="btn btn-outline-secondary p-1 px-2 rounded-0" @click="btnpage(-2)">
                {{curentpage-1}}
              </button>
              <button v-if="curentpage >0" class="btn btn-outline-secondary p-1 px-2 rounded-0" @click="btnpage(-1)">
                {{curentpage}}
              </button>
              <button 
                class="btn btn-outline-secondary p-1 px-3 rounded-0"
              >
                {{ curentpage+1 }}
              </button>
              <button v-if="initPagination>1 && (curentpage+1) < initPagination" class="btn btn-outline-secondary p-1 px-2 rounded-0" @click="btnpage(+1)">
                {{curentpage+2}}
              </button>
              <button class="btn btn-outline-secondary p-1 px-2 rounded-0" @click="btnpage(+1)">
                <i class="bx bx-chevron-right"></i>
              </button>
            </div>
      </div>


        <div class="modal" tabindex="-1" role="dialog" id="modalhapus">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-body">
                        <div class="d-flex justify-content-end">
                        <button type="button" class="close btn" id="closeModal" data-bs-dismiss="modal" aria-bs-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        </div>
                        <div class="modal-sukses">
                            <i class='bx bxs-help-circle'></i>
                            <div>
                                <h3 class="text-center">Hapus Alamat</h3>
                                <p class="text-center">Anda ingin melanjutkan menghapus data alamat ini?</p>
                            </div>
                        </div>
                        <div class="d-flex justify-content-center py-2">
                            <button class="btn btn-red" @click="hapusAlamat()">Hapus</button>
                            <button class="btn btn-light mx-2" data-bs-dismiss="modal" aria-bs-label="Close">Batal</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal" tabindex="-1" role="dialog" id="modaldetail">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                      <h5 class="modal-title" id="exampleModalLabel">Informasi Alamat</h5>
                      <button type="button" id="closeModaldetail" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body p-0">
                    <table class="table table-striped table-hover border-0">
                        <tbody>
                            <tr class="border-0">
                                <td class="border-0"><b>Nama Penerima</b></td>
                                <td class="border-0">: {{datadetail.customer_address?.receipt_name}}</td>
                            </tr>
                            <tr class="border-0">
                                <td class="border-0"><b>No Telepon</b></td>
                                <td class="border-0">: {{datadetail.customer_address?.receipt_phone_number}}</td>
                            </tr>
                            <tr class="border-0">
                                <td class="border-0"><b>Alamat</b></td>
                                <td class="border-0">: {{datadetail.customer_address?.address}}</td>
                            </tr>
                            <tr v-if="address != 'null'" class="border-0">
                                <td class="border-0"><b>Satker</b></td>
                                <td class="border-0">: {{datadetail.name}}</td>
                            </tr>
                            <tr class="border-0">
                                <td class="border-0"><b>Provinsi</b></td>
                                <td class="border-0">: {{datadetail.customer_address?.province?.province_name}}</td>
                            </tr>
                            <tr class="border-0">
                                <td class="border-0"><b>Kabupaten</b></td>
                                <td class="border-0">: {{datadetail.customer_address?.district?.city_name}}</td>
                            </tr>
                            <tr class="border-0">
                                <td class="border-0"><b>Kecamatan</b></td>
                                <td class="border-0">: {{datadetail.customer_address?.sub_district?.subdistrict_name}}</td>
                            </tr>
                        </tbody>
                    </table>
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Tutup</button>
                  </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import axios from 'axios'
// import DashboardUser from '../../components/DashboardUser.vue'
// import FormProfile from '../../components/FormProfile.vue'

export default {
  name: "profile-data-component",
  data() {
    return {
      loading:true,
      listaddress: [],
      address_id:'',
      datadetail:{},
      isisearch: "",
      datalist: [],
      jmlh_value: 5,
      curentpage: 0,
      listpage: null,
      selectedData: [],
      search: "",
      key: "",
      datapage: 0,
    };
  },
  watch: {
    search(value) {
      this.isisearch = value;
      this.curentpage = 0
    },
    user(value){
            if(this.$store.state.address > 0){
                this.listaddress = this.$store.state.address
                this.loading=false
            }
            else if(!(value instanceof Promise) && value !== null && this.$store.state.address == null){

            this.$store.dispatch('address').then(()=>{
                this.listaddress = this.$store.state.address
                this.firstData(this.$store.state.address)
                this.loading=false
            })
            
            }
        }
  },
  computed: {
    user: function() {
        return this.$store.state.user;
    },
    initPagination() {
      return Math.ceil(this.searchData.length / this.jmlh_value);
    },
    searchData: function () {
      return this.listaddress.filter((data) => {
        return (
          data.name.toLowerCase().includes(this.search.toLowerCase()) ||
          data.customer_address?.address.toLowerCase().includes(this.search.toLowerCase())
        );
      });
    },
    filteredData: function () {
      let pageStart = this.curentpage * this.jmlh_value;
      let pageEnd = this.jmlh_value * ((this.curentpage * 1) + 1);

      return this.searchData.filter((item, index) => {
        return index >= pageStart && index < pageEnd;
      });
    },
  },
  created(){
        this.$store.dispatch('address').then(()=>{
          if(this.$store.state.address){
              this.firstData(this.$store.state.address);
              this.listaddress = this.$store.state.address
              this.address_id = this.listaddress.id
              this.loading=false
          }else if(!this.$store.state.address){
              this.loading=false
          }
        })
        
  },
  mounted() {
      
  },
  methods: {
    sendBroker(databroker) {
      this.selectedData = databroker;
    },
    async entries_data(value) {
      this.curentpage = value - 1;
    },
    btnpage(value) {
        let datapage = this.initPagination
            
       if(this.curentpage <= 0 && value == -1){
            this.curentpage = 0
        } else if (this.curentpage <= 0 && value == -2) {
            this.curentpage = 0
        } else if (this.curentpage >= 0 && this.curentpage < (datapage - 1)) {
            this.curentpage += value  
        } else if (this.curentpage >= 0 && this.curentpage <= datapage - 1 && value == -1) {
            this.curentpage += value  
        } else if (this.curentpage >= 0 && this.curentpage <= datapage - 1 && value == -2) {
            this.curentpage += value  
        }  

    },
    firstData(data) {
      this.datalist = [];
      for (let i = 0; i < this.jmlh_value; i++) {
        if (data.length == i) {
          break;
        }
        let dataval = data[i];
        this.datalist.push(dataval);
      }

    },
    hapusAlamat(){
        this.loading=true
        axios.delete('customer/address/delete/'+this.address_id)
        .then(() => {
                this.loading=true
            this.$store.dispatch('address').then(()=>{
                this.listaddress = this.$store.state.address
                this.firstData(this.$store.state.address)
                this.loading=false
                this.curentpage=0
                // this.showData(this.show)

            }).catch(()=>{{
              this.listaddress = this.$store.state.address
                this.firstData(this.$store.state.address)
                this.loading=false
                this.curentpage=0

            }})
            // axios.get('/customer/address/list/'+this.$store.state.id)
            // .then(res => {
            //     this.listaddress = res.data.data;
            //     this.address_id = this.listaddress.id
            //     this.firstData(res.data.data);
            //     this.curentpage=0
            //     this.loading=false
            //     // this.showData(this.show)
            // })
            // .catch(err =>{
            //     console.log(err)
            // })
        })
        .catch(() => {
            // console.error("There was an error!", error);
        }); 
        this.loading = true
        document.getElementById('closeModal').click();
    },
    showModal(id){
        this.address_id = ''
        this.address_id == '' ? this.address_id = id : ''
    },
    showModaldetail(datadetail){
        this.datadetail = datadetail
    },
    btn_paginate() {
    },
  },
};
</script>

<style scoped>
.btn-add{
  text-align: left;
}
.search-group {
  border: solid 1px #ced4da;
  background-color: #fff;
  border-radius: 20px;
  max-width: 15rem;
}
.search-group input {
  background-color: transparent;
  border: 0px;
}
.tombol-search span {
  background-color: transparent;
  border: 0px;
}

.header-table{
  display: grid;
}
.header-table-left{
  margin-bottom: 1rem;
}
.content-wrapper .content {
  padding: 0 0.5rem;
}
.head_subs{
    display: flex;
}
.collapse .card{
  background-color: transparent !important;
  box-shadow: none !important;
}
.collapsing .card{
  background-color: transparent !important;
  box-shadow: none !important;
}
.data-table{
  overflow-x: scroll;
}
.table-data{
    overflow: auto;
}
/* .table > tbody > tr > td {
  vertical-align: center !important;
  background-color: transparent !important;
  --bs-table-accent-bg: transparent !important;
  vertical-align: center;
  line-height: 50px;
  justify-content: center;
  padding: 15px 15px;
}
.table > tbody > tr > td h6{
    padding-top: 10px;
}
.table > tbody > tr > td h5{
    padding-top: 5px;
} */
.panjang-td-table figure{
  width: 70px;
  height: 70px;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
  margin: 0px;
}
.panjang-td-table img{
  height: 100%;
}
.dalam-td{
    padding-left: 20px;
}
.dalam-td p{
    margin-bottom: 0px;
    color: var(--color-light);
}
.td-installed{
    border: solid 1px #2980B9;
    color: #2980B9;
    background-color: transparent;
    border-radius: 25px;
    line-height: 30px;
    padding: 0px 15px;
    cursor: context-menu;
}
.td-expired{
    border: solid 1px #F1484F;
    color: #F1484F;
    background-color: transparent;
    border-radius: 25px;
    line-height: 30px;
    padding: 0px 15px;
    cursor: context-menu;
}
.td-pending{
    border: solid 1px #F1C40F;
    color: #F1C40F;
    background-color: transparent;
    border-radius: 25px;
    line-height: 30px;
    padding: 0px 15px;
    cursor: context-menu;
}
.data-detail p{
    margin-bottom: 0px !important;
}

@media (min-width: 319px) {
}

@media (min-width: 374px) {
}

@media (min-width: 424px) {
}

@media (min-width: 767px) {
  .btn-add{
    text-align: right;
  }
  .content-wrapper .content {
    padding: 0 0.5rem;
  }
  .user-investasi::before {
    content: " ";
    position: absolute;
    width: 150px;
    right: 10%;
    top: 40px;
    height: 100px;
    background-repeat: no-repeat;
    background-size: contain;
    /* background-image: url("../assets/image/team.svg"); */
  }

  .paginate_button{
      padding: 2px !important;
  }
  .dataTables_wrapper .dataTables_paginate .paginate_button{
      padding: 2px !important;
  }
  .header-table{
    justify-content: space-between;
    display: flex;
  }
  .header-table-left{
    margin-bottom: 0rem;
  }
}

@media (min-width: 1023px) {
}

@media (min-width: 1439px) {
}

@media (min-width: 2000px) {
}
</style>
