<template>
  <div class="product-category section section-modif">

    <div class="product-filter-container container">

      <div class="d-flex justify-content-end">
        <nav aria-label="breadcrumb" class="">
          <ol class="breadcrumb">
            <li class="breadcrumb-item "><router-link to="/" class="custom-link">Beranda</router-link></li>
            <li class="breadcrumb-item"
              v-if="$router.currentRoute._value.query?.kategori"
              :class="[$router.currentRoute._value.query?.sub_kategori ? 'active' : '']">
              <router-link :to="$router.currentRoute._value.path+'?kategori='+$router.currentRoute._value.query.kategori" 
              :class="[$router.currentRoute._value.query?.sub_kategori ? 'custom-link' : 'text-secondary']">{{setKategoriTitle($router.currentRoute._value.query?.kategori)}}</router-link>
            </li>

            <li class="breadcrumb-item active "
              v-if="$router.currentRoute._value.query?.sub_kategori">
              <router-link :to="$router.currentRoute._value.fullPath" class="text-secondary">{{setSubKategoriTitle($router.currentRoute._value.query?.sub_kategori)}}</router-link>
            </li>
  
          </ol>
        </nav>
      </div>

      <div class="product-filter">
        <a class="product-filter-button btn input" @click="setModal(true)" >
          <i class='bx bx-filter'></i>
          <span>Filter</span>
        </a>

        <div class="product-order-container">
          <span>Urutkan:</span>
          <select class="product-order-select" v-model="sort" @change="filterProduct">
            <option v-bind:value="''">Semua</option>
            <option v-bind:value="'populer'">Populer</option>
            <option v-bind:value="'terlaris'">Terlaris</option>
            <option v-bind:value="'termurah'">Termurah</option>
            <option v-bind:value="'terbaru'">Terbaru</option>
          </select>
        </div>  
      </div>
    </div>

    <Product>
      <div  class="product-content d-flex justify-content-center align-items-center" v-if="loading === true" >
          <div class="lds-facebook"><div></div><div></div><div></div></div>
      </div> 
      <div class="product-content" v-else-if="dataProducts.length > 0">
        <product-list 
          v-for="(product, index) in dataProducts" 
          :key="product" 
          :index="index"
          :id="product.id"
          :img="product.image"
          :title="product.name"
          :desc="product.descriptions"
          :price="product.price"
          :promo="product.promo"
          :rating="product.star_review"
          :status="product.status"

          >
          
          </product-list>
      </div>
      <div v-else class="product-content-null">
        <div>
          <img :src="require(`../assets/img/empty-illustration.svg`)" alt="">
          <p class="text-center"><b>Produk tidak ditemukan ..</b></p>
        </div>
      </div>
    </Product>
    <!-- <nav aria-label="Page navigation example">
      <ul class="pagination">
        <li class="page-item">
          <a class="page-link" href="#" aria-label="Previous">
            <span aria-hidden="true">&laquo;</span>
          </a>
        </li>
        <li class="page-item">
          <router-link class="page-link" to="/produk/promo?page=1">1</router-link>
        </li>
        <li class="page-item">
          <a class="page-link" href="#" aria-label="Next">
            <span aria-hidden="true">&raquo;</span>
          </a>
        </li>
      </ul>
    </nav> -->
    <pagination v-if="dataProducts.length > 0"
        :pagination="pagination"
        :page="page"
        :lastPage="lastPage"
      ></pagination>

    <Modal
      :show="modal ? true : false "
      :header="true"
      :headerBorder="true"
      :title="true"
      :titleText="'Filter'"
      :footer="true"
      :footerBtnPosition="'center'"
      :footerBorder="true"
      :btnCancel="false"
      :btnSubmit="true"
      :btnSubmitText="'Filter'"
      :btnCancelText="''"
      :btnSubmitWidth="'full'"
      :btnCancelWidth="'auto'"
      @closeModal="closeModal"
      @submitModal="submitModal"
    >
      <div class="filter-container">
        <p class="title">Kategori</p>
        <div class="list-kategori-container">
          <ul class="accordion-custom">
            <li v-for="(kategori, index) in dataCategories" :key="index" >
              <a v-if="kategori.subcategory.length > 0" class="accordion-custom-btn" @click="openAccordion($event)" :class="[this.kategoriIdCadangan.includes(kategori.id) ? 'active' : '']">
                <label class="">
                  <input v-if="this.kategoriIdCadangan ===  kategori.id" type="checkbox" :value="kategori.id" checked v-model="kategoriIdCadangan" v-on:change="setSubKategoriId()">
                  <input v-else type="checkbox" :value="kategori.id" v-model="kategoriIdCadangan" v-on:change="setSubKategoriId()">
                  <span class="label-checkbox"><b>{{kategori.name}}</b></span>
                </label>
                <span class="icon"><i class='bx bx-chevron-down'></i></span>  
              </a>

              <a v-else class="accordion-custom-btn" :class="[this.kategoriIdCadangan.includes(kategori.id) ? 'active' : '']" :id="'kategori-'+kategori.id">
                <label class="">
                  <input v-if="this.kategoriIdCadangan ==  kategori.id" type="checkbox" :value="kategori.id" checked v-model="kategoriIdCadangan" v-on:change="setSubKategoriId()">
                  <input v-else type="checkbox" :value="kategori.id" v-model="kategoriIdCadangan" v-on:change="setSubKategoriId()">
                  <span class="label-checkbox"><b>{{kategori.name}}</b></span>
                </label>
              </a>
              <ul class="accordion-custom-content " v-if="kategori.subcategory.length > 0">
                <li class="subkategori-item pl-3"  v-for="(subcategory, number) in kategori.subcategory" :key="number">
                  <label class="checkbox-container">
                    <input v-if="this.subKategoriIdCadangan.includes(subcategory.id)" :class="'kategori-'+kategori.id" class="subkategori-checkbox" type="checkbox" :value="subcategory.id" checked v-model="subKategoriIdCadangan" v-on:change="setKategoriId()">
                    <input v-else type="checkbox" :class="'kategori-'+kategori.id" class="subkategori-checkbox" :value="subcategory.id" v-model="subKategoriIdCadangan" v-on:change="setKategoriId()">
                    <span class="label-checkbox">{{subcategory.name}}</span>
                  </label>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <p class="form-filter-title">Harga</p>
        <div class="min-max row ">
          <div class="form-group col-12 col-md-6 mb-3">
            <label class="form-label w-100">Min</label>
            <input type="number" class="input input-filter w-100" min="0" placeholder="0" v-model="minPrice">
          </div>
          <div class="form-group col-12 col-md-6 mb-3">
            <label class="form-label w-100">Max</label>
            <input type="number" class="input input-filter w-100" :min="minPrice" placeholder="0" v-model="maxPrice">
          </div>
        </div>

        <div class="form-group">
          <label class="form-label">Rating Produk</label>
          <select class="input mb-3 w-100" v-model="rating">
            <option v-bind:value="''">Semua</option>
            <option v-bind:value="'1'">1 Satu</option>
            <option v-bind:value="'2'">2 Dua</option>
            <option v-bind:value="'3'">3 Tiga</option>
            <option v-bind:value="'4'">4 Empat</option>
            <option v-bind:value="'5'">5 Lima</option>
          </select>
        </div>

        <div class="form-group">
          <label class="form-label">Urutkan</label>
          <select class="input mb-3 w-100" v-model="sort">
            <option v-bind:value="''">Paling sesuai</option>
            <option v-bind:value="'terpopuler'">Terpopuler</option>
            <option v-bind:value="'termurah'">Termurah</option>
            <option v-bind:value="'terbaru'">Terbaru</option>
          </select>
        </div>          
      </div>
    </Modal>

  </div>
</template>

<script>

import axios from 'axios'

import Product from '../components/Product.vue'
import ProductList from '../components/ProductList.vue'
import Pagination from '../components/Pagination.vue'
import Modal from '../components/Modal.vue'

export default {
  name: 'product-category',
  props: ['dataCategories','dataSubCategories'],
  data(){
    return{
      allDataProducts:[],
      recProduct:[],
      dataProducts:[],
      kategoriId:[],
      kategoriIdCadangan:[],
      categories_title:[],
      subcategories_title:[],
      kategori:'', 
      subKategoriId:[],
      subKategoriIdCadangan:[],
      subKategori:'', 
      sort:'',
      urutkan:'',
      promo: false,
      rating: '',
      minPrice:'',
      maxPrice:'',
      page: '',
      lastPage: '',
      pageTotal: '',
      search: '',
      'loading':true,
      'show' : 12,
      'pagination':{
          'currentPage' : 1,
          'nextPage':false,
          'prevPage':false,
          'from':0,
          'to':0,
          'totalData': 0,
          'maxLink':5,
          'linkPagination': []
      },
      'data_search': '',
      modal : false,
      urlProduk: Object.keys(this.$router.currentRoute._value.query)
    }
  },
  components: {
    Product,
    ProductList,
    Pagination,
    Modal
  },
  methods: {
    // Modal Filter
    setModal(condition){
      this.modal = condition
    },
    closeModal(){
      this.setModal(false)
      this.setDataFilter()
    },
    submitModal(){
      this.setModal(false)
      this.filterProduct()
    },

    // pagination  -------------
    setPagination(){
        let totalDataWillShow = 0;
        let from = 0;
        let currentPage = 0;    
        
        let page = this.page
        if(page != ''){
          totalDataWillShow = parseInt(this.show) * parseInt(page);
          from = page > 1 ? parseInt((page - 1) * this.show) + 1 : 1
          currentPage = parseInt(page)
        }else{
          totalDataWillShow = this.pagination.totalData >= this.show ? this.show : this.pagination.totalData;
          currentPage = 1
          from = 1 
        }

        this.pagination.currentPage = currentPage
        this.pagination.from = from
        this.pagination.to = this.setDataPaginationTo(totalDataWillShow)
        this.pagination.prevPage = this.pagination.from <= 1 ? false : true
        this.pagination.nextPage = this.pagination.totalData > this.pagination.to ? true : false

        this.pagination.linkPagination = this.setLinkPagination()
    },
    setLinkPagination(){
        // set number link
        let arrayLink = []
        // if(this.urlProduk.length === 0 && Object.keys(this.$router.currentRoute._value.params)[0] !== 'promo'){
          // let totalLink = this.pagination.totalData / this.show
          // for(let i = 1; i <= Math.ceil(totalLink); i++){
              // arrayLink.push(i)
          // }
        // } else{
          let totalLink = this.pageTotal
          for(let i = 1; i <= totalLink; i++){
              arrayLink.push(i)
          }
        // }
        // let totalLink = this.pagination.totalData / this.show
        // let totalLink = this.pageTotal
        // let arrayLink = []
        // for(let i = 1; i <= totalLink; i++){
        // // for(let i = 1; i <= Math.ceil(totalLink); i++){
        //     arrayLink.push(i)
        // }

        let currentPage = 0
        if(this.pagination.currentPage > 1){
            currentPage  = parseInt(this.pagination.currentPage-2)
        }
        arrayLink = arrayLink.slice(currentPage, parseInt(currentPage + this.pagination.maxLink))
        this.lastPage = arrayLink[arrayLink.length - 1]
        return arrayLink
    },
    setDataPaginationTo(totalDataWillShow){
        let paginationTo = 0
        /**
         * Menentukan nilai this.pagination.to
         * 
         * Jika total seluruh data kurang dari jumlah data yang akan ditampilkan
         * contoh : 
         * total seluruh data  = x, x= 8
         * jumlah data yang akan di tampilkan = y, y=10
         * 
         * z = y + ( x - y )
         * z = 10 + (8 - 10)
         * z = 10 - 2
         * z = 8  -> this.pagination.to
         */
        if( this.pagination.totalData  < totalDataWillShow){
            paginationTo = totalDataWillShow + ( this.pagination.totalData - totalDataWillShow )
        }
        /**
         * 
         * Jika total seluruh data lebih dari jumlah data yang akan ditampilkan
         * contoh : 
         * total seluruh data  = x, x= 20,
         * jumlah data yang akan di tampilkan = y, y= 10
         * 
         * z = x - ( x - y )
         * z = 20 + (20 - 10)
         * z = 20 - 10
         * z = 10  -> this.pagination.to
         */
        else{
            paginationTo = this.pagination.totalData - ( this.pagination.totalData - totalDataWillShow )
        }

        return paginationTo
    },
    showData(data_show){
      this.loading = true
      let page = this.page
      this.show =  data_show ? data_show : this.show
      if(page != ''){
        this.pagination.totalData = Object.values(this.allDataProducts).length
        this.setPagination()
        // if(this.urlProduk.length === 0 && Object.keys(this.$router.currentRoute._value.params)[0] !== 'promo'){
          // this.dataProducts = Object.values(this.allDataProducts).slice(parseInt(this.pagination.from - 1), parseInt(this.pagination.to))
        // } else{
          this.dataProducts = Object.values(this.allDataProducts)
        // }
        // this.dataProducts = Object.values(this.allDataProducts).slice(parseInt(this.pagination.from - 1), parseInt(this.pagination.to))
        // this.dataProducts = Object.values(this.allDataProducts)
      }else{
        this.page = 1
        
        // if (this.urlProduk.length === 0 && Object.keys(this.$router.currentRoute._value.params)[0] !== 'promo') {
          // this.dataProducts = Object.values(this.allDataProducts).slice(0, parseInt(this.show))
        // } else {
          this.dataProducts = Object.values(this.allDataProducts)
        // }
        // this.dataProducts = Object.values(this.allDataProducts).slice(0, parseInt(this.show))
        this.pagination.totalData = Object.values(this.allDataProducts).length
        this.setPagination()
      }

    },

    
    // data Produk  -------------
    setDataFilter(){
      /** Fungsi ini untuk mengatur parameter filter data yang didapat dari url, parameter filter akan digunakan untuk mangambil data api*/

      /** Set Paramater Kategori ID & Sub Kategori ID*/
      let kategori_id = []
      let subkategori_id = []
      
      if(this.$router.currentRoute._value.query.kategori){
        let routeKategori = this.$router.currentRoute._value.query.kategori
        let id_kategori =  routeKategori.split(',')
        id_kategori.forEach(element => {
          kategori_id.push(parseInt(element))
        });
      }
      this.kategoriIdCadangan =  kategori_id
      this.kategoriId =  kategori_id

      if(this.$router.currentRoute._value.query.sub_kategori && this.$router.currentRoute._value.query.kategori){
        let routeSubKategori = this.$router.currentRoute._value.query.sub_kategori
        let id_subkategori =  routeSubKategori.split(',')
        id_subkategori.forEach(element => {
          subkategori_id.push(parseInt(element))
        });
      }
      this.subKategoriIdCadangan =  subkategori_id
      this.subKategoriId =  subkategori_id

      /** Set Price */
      this.minPrice = this.$router.currentRoute._value.query.min_price ? this.$router.currentRoute._value.query.min_price : ''
      this.maxPrice = this.$router.currentRoute._value.query.max_price ? this.$router.currentRoute._value.query.max_price : ''

      /** Set Rating */
      this.rating = this.$router.currentRoute._value.query.rating ? this.$router.currentRoute._value.query.rating : ''

      /** Set Sort */
      this.sort = this.$router.currentRoute._value.query.sort ? this.$router.currentRoute._value.query.sort : ''

      /** Set Page */
      this.page = this.$router.currentRoute._value.query.page ? this.$router.currentRoute._value.query.page : 1

    },
    openAccordion(event){
      /** Fungsi ini untuk mengatur accordion di modal filter, kategori dan subkategori */
      const accordion = document.querySelectorAll('.accordion-custom-link');
      if(event.currentTarget.classList.contains("active")){
        event.currentTarget.classList.remove("active")
      }else{
        accordion.forEach(n => {
          n.classList.remove('active')
        });
        event.currentTarget.classList.add("active")
      }

    },
    async getProduct(){
      /** Fungsi ini untuk mengambil data */

      this.setDataFilter()
      let formdata = new FormData()
      let url = 'product/filter'
      let method = 'post'

      if(this.subKategoriId.length > 0){
        this.subKategoriId.forEach(element => {
          formdata.append('subcategory[]', element)
        })
      }

      if( this.kategoriId.length > 0){
        this.kategoriId.forEach(element => {
          formdata.append('category[]', element)
        })
      }

      if(Object.keys(this.$router.currentRoute._value.params)[0] === 'promo'){
        // url = 'product/promo'
        // method = 'get'
        // formdata = ''
        this.promo = true
        // console.log('Masuk Promo', this.promo)
      } else {
        this.promo = false
      }

      // if(this.$router.currentRoute._value.fullPath == '/produk'){
      //   this.page = 1
      // } else{
      //   this.page
      // }
      
      this.minPrice != '' ? formdata.append('price_min', parseInt(this.minPrice)) : this.minPrice = ''
      this.maxPrice != '' ? formdata.append('price_max', parseInt(this.maxPrice)) : this.maxPrice = ''
      this.rating ? formdata.append('rating', parseInt(this.rating)) : this.rating = ''
      this.sort ? formdata.append('sort', this.sort) : this.sort = ''
      this.search ? formdata.append('search', this.search) : this.search = ''
      this.page ? formdata.append('page', this.page) : this.page = ''
      this.promo ? formdata.append('promo', this.promo) : this.promo = false

      // if(this.search != ''){
      //   url = 'product?search='+this.search
      //   method = 'get'
      //   formdata = ''
      // }
      
      // if(Object.keys(this.$router.currentRoute._value.params)[0] === 'promo'){
      //   // url = 'product/promo'
      //   // method = 'get'
      //   // formdata = ''
      //   this.promo = true
      //   console.log('Masuk Promo', this.promo)
      // }

      // if(Object.keys(this.$router.currentRoute._value.params)[0] === 'rekomendasi'){
      //   url = 'product/rekomendasi'
      //   method = 'get'
      //   formdata = ''
      // }
      let urlPage = '?page='
      if(this.$router.currentRoute._value.fullPath == '/produk'){
        // url = 'product'
        // method = 'get'
        // formdata = ''
        this.$router.push(this.$router.currentRoute._value.fullPath+urlPage+this.page)
      }
      // if(Object.keys(this.$router.currentRoute._value.query).length == 0){
      //   this.$router.push('/produk?page=1')
      // }

      try{
        const {data} = await axios({
            method: method,
            url: url,
            data: formdata
        });
        if (Object.keys(this.$router.currentRoute._value.params)[0] === 'promo' || Object.keys(this.$router.currentRoute._value.query).length > 0) {
          this.allDataProducts = data.data.products
          this.pageTotal = data.data.pageTotal
        } else {
          this.allDataProducts = data.data
        }
        this.showData(this.show)
      }catch(err){
          console.log(err)
      }
    },
    // getRekomendasi(){
    //   // this.loadRecProduct = true
    //   axios.get(`product/customer/recomendation`)
    //   .then(res => {
        
    //     this.recProduct = res.data.data
    //     // console.log('rec_product', this.recProduct);
    //   }).catch(() => {
    //     // this.loadRecProduct = true
    //     // console.log(err.response);
    //   })
    // },
    setSubKategoriId(){
      // ketika centang kategori set data subkategori sesuai dengan kategori yang dipilih
      // centang semua subkategori, sesuai dengan kategori yang dipilih
      this.subKategoriIdCadangan = []
      this.kategoriIdCadangan.forEach(kategori_id => {
        let check_kategori = document.querySelectorAll('.kategori-'+kategori_id)
        check_kategori.forEach(check => {
          
          if(! this.subKategoriIdCadangan.includes(parseInt(check.value))){
            this.subKategoriIdCadangan.push(parseInt(check.value))
          }
        });
      });

    },
    setKategoriId(){
      // centang semua kategori, sesuai dengan subkategori yang dipilih
      this.kategoriIdCadangan = []
      this.dataCategories?.forEach(kategori => {
        if(kategori.subcategory.length > 0){
          for(let i = 0; i < kategori.subcategory.length; i++){
            if(this.subKategoriIdCadangan.includes(kategori.subcategory[i].id)){
              if(! this.kategoriIdCadangan.includes(kategori.subcategory[i].category_id)){
                this.kategoriIdCadangan.push(kategori.subcategory[i].category_id)
              }
            }
          }
        }
      });
    },
    setKategoriTitle(id){
      let categories_title = []
      let kategoriID = String(id).split(',')
      if(kategoriID != undefined){
        let kategoriIDInteger = kategoriID.map(function (x) { 
          return parseInt(x, 10); 
        });
        this.dataCategories?.forEach(kategori => {
          if(kategoriIDInteger.includes(kategori.id)){
            categories_title.push(kategori.name)
          }
        });

        if(categories_title.length == 1){
          return categories_title[0]
        }else if(categories_title.length > 1){
          return 'Filter'
        }else{
          return ''
        }
      }else{
          return ''
      }

    },
    setSubKategoriTitle(id){
      let subcategories_title = []
      let subkategoriID = String(id).split(',')
      if(subkategoriID != undefined){
        let subkategoriIDInteger = subkategoriID.map(function (x) { 
          return parseInt(x, 10); 
        });
        this.dataSubCategories?.forEach(subkategori => {
          if(subkategoriIDInteger.includes(subkategori.id)){
            subcategories_title.push(subkategori.name)
          }
        });

        if(subcategories_title.length == 1){
          return subcategories_title[0]
        }else if(subcategories_title.length > 1){
          return 'Filter'
        }else{
          return ''
        }
      }else{
        return ''
      }

    },
    filterProduct(){
      this.kategoriId = this.kategoriIdCadangan
      this.subKategoriId = this.subKategoriIdCadangan
      let p_subkategori = this.subKategoriId.length > 0 ? 'sub_kategori='+this.subKategoriId : ''
      let p_kategori = this.kategoriId.length > 0 ? 'kategori='+this.kategoriId : ''
      let p_search = 'search='+this.search
      let p_minprice = 'min_price='+this.minPrice
      let p_maxprice = 'max_price='+this.maxPrice
      let p_rating = 'rating='+this.rating
      let p_sort = 'sort='+this.sort
      // let p_page = this.page

      let array_filter = []
      array_filter.push(p_subkategori)
      array_filter.push(p_kategori)
      array_filter.push(p_search)
      array_filter.push(p_minprice)
      array_filter.push(p_maxprice)
      array_filter.push(p_rating)
      array_filter.push(p_sort)
      // array_filter.push(p_page)

      let url = ''
      // let currentUrl = this.$router.currentRoute._value.query.search
      let promo = this.$router.currentRoute._value.params.promo
      
      for(let i = 0; i < array_filter.length; i ++){
        if(array_filter[i].substring(array_filter[i].indexOf('=') + 1) != ''){
          if(url == ''){
          // if(url == ){
            
            url += '?'+array_filter[i]
          }else{
            url += '&'+array_filter[i]
          }
        }
      }

      this.$router.push('/produk'+(promo === 'promo' ? '/promo' : '')+url)
      // this.$router.push('/produk'+(promo === 'promo' ? '/promo' : '')+(currentUrl ? '?search='+currentUrl : '')+url)
      // this.$router.push('/produk'+'?search='+url)
    },
    setSearch(){
      //if search is '' redirec to /produk
      const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
      });
      // Get the value of "some_key" in eg "https://example.com/?some_key=some_value"
      let search = params.search;
      if(search != null){
        this.search = search
        if( this.search == '' && search != null){ 
          this.$router.push('/produk')
        }
      }else{
        this.search = ''
        // if(this.$router.currentRoute._value.path === '/produk'){
        //   this.$router.push('/produk')
        //   console.log('product: ', this.allDataProducts)
        // }
        // this.page = 1
      }
    },
    getUser(){
      axios.get(`user`)
      .then(() => {
        // console.log('user: ', res);
          // this.user = res.data.data
      }).catch(() => {
        // console.log(err.response);
        // if (err.response.data.message == 'Unauthenticated.'){
        //   this.$cookies.remove('token')
        //   // this.$router.push('/login')
        // }
      })
    },
    autoLogout(){
      axios.interceptors.response.use( (response) => {
            // Return a successful response back to the calling service
            return response;
        }, (error) => {
            // console.log('response error', error.response)
            if (error.response.status == 401) {
              if (error.response.data.message == "Unauthenticated.") {
                  // Hapus token di cookie
                  this.$cookies.remove('token')
                  this.$store.dispatch('cart')
                  // Redirect Home atau login
                  this.$router.push('/login')
              }
          }

          return Promise.reject(error.response);
        })
    }
  },
  beforeRouteEnter(to, from, next) {

    if(to.query?.sub_kategori && !to.query?.kategori){
      let sub_kategori = to.query?.sub_kategori
      let dataKategori = []
      let subkategori_id = []
      let kategori_id = []

      axios.get('category')
      .then(response => {
        dataKategori = response.data.data

        let id_subkategori =  sub_kategori.split(',')
        id_subkategori.forEach(element => {
          subkategori_id.push(parseInt(element))
        });

        dataKategori.forEach(kategori => {
          if(kategori.subcategory){
            kategori.subcategory.forEach(subcat =>{
              if(subkategori_id.includes(subcat.id)){
                if(!kategori_id.includes(subcat.category_id)){
                  kategori_id.push(subcat.category_id)
                }
              }
            })
          }
        })

        let query = to.query
        query.kategori = kategori_id.toString()
        next({ 
          path: to.path,
          query: query
        })
      })
      .catch(() => {
          // console.log(error)
      })

    }else{
      next()
    }
  },

  mounted(){
    if(this.$cookies.get('token')){
      this.getUser()
    }
    this.autoLogout()
    this.setSearch()
    this.page = this.$router.currentRoute._value.query.page ?this.$router.currentRoute._value.query.page : ''
    this.getProduct()
    // this.getRekomendasi()
    // console.log('params: ', this.$router.currentRoute._value.params.promo);
    // console.log('query: ', this.$router.currentRoute._value.path);
  },
  watch:{
    '$route.query': {
      handler: function() {
        this.setSearch()
        this.getProduct()
        // this.getRekomendasi()
        // if(this.$router.currentRoute._value.params.promo === 'rekomendasi'){
        //   this.allDataProducts = this.recProduct
        //   // console.log('rec Product', this.allDataProducts)
        // }
      },
      deep: true,
      immediate: true
    },
    dataProducts: {
        deep: true,
        handler: function () {
          this.loading = false
        }
    },
  },
}
</script>

