import { createWebHistory, createRouter } from "vue-router";
import VueCookies from 'vue-cookies';
import store from '../vuex'

import PageNotFound from '../views/PageNotFound.vue'
import Faq from '../views/Faq.vue'
import Term from '../views/TermsConditions.vue'
import Privacy from '../views/PrivacyPolice.vue'
import About from '../views/AboutView.vue'
import Contact from '../views/ContactView.vue'

//auth
import Logindata from '../views/Auth/LoginView.vue'
import Registerdata from '../views/Auth/RegisterView.vue'
import Forgotpass from '../views/Auth/ForgotPasswordView.vue'
import ResetPassword from '../views/Auth/ResetPasswordView.vue'


//home
import Home from '../views/Home.vue'
import Profile from '../views/Profile.vue'
import ProductCategory from '../views/ProductCategory.vue'
import ProductDetail from '../views/ProductDetail.vue'
import KeranjangBelanja from '../views/KeranjangBelanja.vue'
import Checkout from '../views/Checkout.vue'
import ConfirmationOrder from '../views/ConfirmationOrder.vue'
import ConfirmationPayment from '../views/ConfirmationPayment.vue'

//dashboard
import DashboardUser from '../components/DashboardUser.vue'
import DataRespon from '../components/TransaksiUser/PengecekanPembayaran.vue'
import EditProfile from '../components/SuntingUser/FormProfile.vue'
import SkeletonProfile from '../components/SkeletonUser/SkeletonSuntingProfile.vue'
import ComplainData from '../components/ComplainUser/ComplainData.vue'
import AddComplainData from '../components/ComplainUser/AddComplain.vue'
import DetailComplain from '../components/ComplainUser/DetailComplain.vue'
import Datatransaksi from '../views/TransaksiUser/ListTransaksi.vue'
import DetailPesananData from '../views/TransaksiUser/DetailTransaksi.vue'
import Ulasan from '../components/Ulasan/DetailUlasan.vue'
import AddUlasan from '../components/Ulasan/AddUlasan.vue'
import TableAddress from '../components/AddressUser/TableAddress.vue'
import FormUserAddress from '../components/AddressUser/FormUserAddress.vue'
import EditUserAddress from '../components/AddressUser/FormEdituser.vue'
import ProfileChat from '../components/ProfileChat.vue'

//Example Page
import BpdPayment from '../components/Example/BpdPayment.vue'
import DokuPaymentPending from '../components/Example/DokuPaymentPending.vue'
import DokuPaymentSuccess from '../components/Example/DokuPaymentSuccess.vue'

const routes= [
    { path: '/:pathMatch(.*)*', component: PageNotFound },
    {
        path: '/example/bpd-payment',
        name: 'bpdPayment',
        component: BpdPayment,
        props: route => ({ query: route.query.id_transaksi }),
                meta: {
                    requiresAuth: true
                }
    },
    {
        path: '/example/doku-payment-pending',
        name: 'dokuPaymentPending',
        component: DokuPaymentPending,
    },
    {
        path: '/example/doku-payment-success',
        name: 'dokuPaymentSuccess',
        component: DokuPaymentSuccess,
    },
    {
        path: '/login',
        name: 'login',
        component: Logindata,
    },
    {
        path: '/register',
        name: 'register',
        component: Registerdata,
    },
    {
        path: '/lupa-password',
        name: 'lupa-password',
        component: Forgotpass,
    },
    {
        path: '/reset-password',
        name: 'reset-password',
        component: ResetPassword,
    },
    {
        path: '/',
        name: 'home',
        component: Home,
    },
    {
        path: '/faq',
        name: 'faq',
        component: Faq,
    },
    {
        path: '/tentang-kami',
        name: 'about',
        component: About,
    },
    {
        path: '/hubungi-kami',
        name: 'contact',
        component: Contact,
    },
    {
        path: '/term-condition',
        name: 'term',
        component: Term,
    },
    {
        path: '/privacy-police',
        name: 'privacy',
        component: Privacy,
    },
    {
        path: '/produk',
        name: 'ProductCategory',
        component: ProductCategory,
        props: route => ({ query: route.query.search }),
        children: [
            {
                path: '/produk/:promo',
                component: ProductCategory,
            },
            {
                path: '/produk/:rekomendasi',
                component: ProductCategory,
            }
        ]
    },
    {
        path: '/profil',
        name: 'Profile',
        component: Profile,
        redirect: VueCookies.isKey('token') ? '/profil/dashboard' : '/login',
        // props: (route) => ({ param: route.params || 'dashboard' }) 
        children: [
            {
                path: '/profil/beranda',
                component: DashboardUser,
                name: 'Beranda Pengguna',
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/profil/sunting-profil',
                component: EditProfile,
                name: 'Sunting Pengguna',
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/profil/skeleton-sunting',
                component: SkeletonProfile,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/profil/alamat',
                name: 'Alamat',
                component: TableAddress,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/profil/komplain',
                name: 'Data Komplain',
                component: ComplainData,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/profil/addkomplain',
                name: 'Tambah Komplain',
                component: AddComplainData,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/profil/detailkomplain',
                name: 'Detail Komplain',
                component: DetailComplain,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/profil/transaksi',
                name: 'Transaksi',
                component: Datatransaksi,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/profil/detailtransaksi',
                name: 'Detail Transaksi',
                component: DetailPesananData,
                props: route => ({ query: route.query.id_transaksi }),
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/profil/ulasan',
                name: 'Ulasan',
                component: Ulasan,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/profil/addulasan',
                name: 'Tambah Ulasan',
                component: AddUlasan,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/profil/tambah-alamat',
                component: FormUserAddress,
                name: 'Tambah Alamat',
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/profil/edit-alamat',
                component: EditUserAddress,
                name: 'Edit Alamat',
                props: route => ({ query: route.query.id_alamat }),
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/profil/chat',
                component: ProfileChat,
                name: 'Chat',
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/profil/respon',
                component: DataRespon,
                name: 'Status Pembayaran',
                meta: {
                    requiresAuth: true
                }
            },
        ]
    },
    {
        path: '/produk-detail',
        props: route => ({ query: route.query.id_produk }),
        component: ProductDetail,
    },
    {
        path: '/keranjang-belanja',
        name: 'KeranjangBelanja',
        meta: {
            requiresAuth: true
        },
        component: KeranjangBelanja,
    },
    {
        path: '/checkout',
        name: 'Checkout',
        meta: {
            requiresAuth: true
        },
        component: Checkout,
    },
    {
        path: '/confirmation-order',
        name: 'ConfirmationOrder',
        meta: {
            requiresAuth: true
        },
        component: ConfirmationOrder,
    },
    {
        path: '/confirmation-payment',
        name: 'ConfirmationPayment',
        meta: {
            requiresAuth: true
        },
        component: ConfirmationPayment,
    }
];


const router = createRouter({
    history: createWebHistory(),
    routes,
});  

router.beforeEach((to, from, next) => {

    if (to.matched.some(record => record.meta.requiresAuth)) {
      // this route requires auth, check if logged in
      // if not, redirect to login page.
      if (!store.getters.authenticated) {
        next({ name: 'login' })
      } else {
        next() // go to wherever I'm going
      }
    } else {
      next() // does not require auth, make sure to always call next()!
    }
})
export default router;
