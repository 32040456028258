<template>
      
      <div class="profile section section-modif mb-5">

        <div class="profile-container container">

          <div class="d-flex justify-content-end">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="/">Beranda</a></li>
                <li class="breadcrumb-item active text-capitalize" aria-current="page" v-if="menuProfil != 'beranda'">
                    {{ $route.name }}
                </li>
              </ol>
            </nav>
          </div>

          <div class="profile-content-container">
            <div class="profile-menu">
            <ul class="list-group-custom" id="profilList">
                <li>
                <router-link to="/profil/beranda" 
                id="dashboard" 
                :class="[{'router-link-active active': this.$router.currentRoute._value.href === '/profil/beranda'}]"
                class="list-group-custom-item dashboard text-capitalize border-bottom"><i class='bx bxs-dashboard' ></i> Beranda</router-link>
                </li>
                <li>
                <router-link to="/profil/sunting-profil" 
                id="sunting-profil" 
                :class="[{'router-link-active active': this.$router.currentRoute._value.href === '/profil/sunting-profil'}]"
                class="list-group-custom-item form text-capitalize border-bottom"><i class='bx bxs-user' ></i> Sunting Pengguna</router-link>
                </li>
                <li>
                <router-link to="/profil/transaksi"
                id="transaksi"
                :class="[{'router-link-active active': this.$router.currentRoute._value.href === '/profil/transaksi' || this.$router.currentRoute._value.href === '/profil/detailtransaksi'+'?id_transaksi='+this.$router.currentRoute._value.query.id_transaksi}]"
                class="list-group-custom-item text-capitalize border-bottom"><i class='bx bx-list-check' ></i> Daftar Transaksi</router-link>
                </li>
                <!-- <li>
                <router-link to="/profil/komplain"
                id="komplain"
                :class="[{'router-link-active active': this.$router.currentRoute._value.href === '/profil/komplain' || this.$router.currentRoute._value.href === '/profil/addkomplain'}]"
                class="list-group-custom-item text-capitalize border-bottom"><i class='bx bxs-angry' ></i> Komplain</router-link>
                </li> -->
                <li>
                <router-link to="/profil/chat"
                id="chat"
                :class="[{'router-link-active active': this.$router.currentRoute._value.href === '/profil/chat'}]"
                class="list-group-custom-item text-capitalize border-bottom"><i class='bx bxs-message-dots' ></i> Chat <span class="p-1 background-primary rounded-circle font-smaller">{{jumlahChatBaru}}</span></router-link>
                </li>
                <li>
                <router-link to="/profil/alamat"
                id="alamat"
                :class="[{'router-link-active active': this.$router.currentRoute._value.href === '/profil/alamat' 
                || this.$router.currentRoute._value.href === '/profil/tambah-alamat'
                || this.$router.currentRoute._value.href === '/profil/edit-alamat'+'?id_alamat='+this.$router.currentRoute._value.query.id_alamat}]"
                class="list-group-custom-item text-capitalize border-bottom"><i class='bx bxs-location-plus' ></i> Alamat</router-link>
                </li>
                <!-- <li>
                <router-link to="/profil/ulasan"
                id="ulasan"
                :class="[{'router-link-active active': this.$router.currentRoute._value.href === '/profil/ulasan'}]"
                class="list-group-custom-item text-capitalize border-bottom mb-3"><i class='bx bxs-star' ></i> Ulasan</router-link>
                </li> -->
                <li>
                  <a class="list-group-custom-item text-capitalize border-bottom mb-3" role="button" data-bs-toggle="modal" data-bs-target="#modalLogout"><i class='bx bxs-log-out'></i> Keluar</a>
                </li>
            </ul>
            </div>

            <div class="profile-content">
              <div class="background-white p-3">
                <div class="form-profile-header border-0">
                  <h3 class="title text-capitalize">{{ $route.name }}</h3>
                  <hr class="border-light m-0">
                </div>
                <router-view
                ></router-view>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="modal fade" id="modalLogout" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-body">
              <div class="d-flex justify-content-end">
                <button type="button" class="close btn" id="closeModal" data-bs-dismiss="modal" aria-bs-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="d-flex align-items-center justify-content-center text-center">
                <div>
                  <i class='bx bxs-error-circle' style="font-size:72px; color: #E40C0C;"></i>
                  <p>Anda yakin ingin keluar?</p>
                </div>
              </div>
              <div class="d-flex justify-content-center py-2">
                <button type="button" @click="logout()" class="btn btn-red py-2 mt-2"><i class='bx bxs-check-square'></i> Keluar</button>
                <button type="button" class="btn btn-light py-2 mt-2 me-2" data-bs-dismiss="modal"><i class='bx bxs-minus-circle'></i> Batal</button>
              </div>
            </div>
          </div>
        </div>
      </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'profile-component',
  data(){
    return{
      profilContent: 'dashboard',
      menuProfil: '',
      jumlahChatBaru: 0,
      databarulink:'',
      dataPesan:[]
    }
  },
  methods: {
    setMenuProfil(){
      let profileMenu = this.$router.currentRoute._value.href
      profileMenu =  profileMenu.replace('/profil/','')
      profileMenu = profileMenu.split('?')[0]
      this.menuProfil = profileMenu.replace('-',' ')
    },
    logout(){
      // this.setModal('close')
      this.$cookies.remove('token')
      this.$cookies.remove('id_checkout')
      this.$cookies.remove('id_transaction')
      this.$router.push('/')
      this.auth = false
      window.location.reload()
    },
    getUser(){
      axios.get(`user`)
      .then(() => {
        // console.log('user: ', res);
          // this.user = res.data.data
      }).catch((err) => {
        // console.log(err.response);
        if (err.response.data.message == 'Unauthenticated.'){
          this.$cookies.remove('token')
          // this.$router.push('/login')
        }
      })
    },
    // async getDataPesan(){
    //     try{
    //         const {data} = await axios({
    //             method: 'get',
    //             url: 'usermanagement/chat/detail',
    //         });
    //         console.log(data)
    //         this.dataPesan = data.data
    //     }catch(err){
    //         console.log(err)
    //     }
    // },
    async cekdatapesan(){
      await this.$store.dispatch('datapesan').then(() => {
        this.jumlahChatBaru = this.$store.state.menuchat
      })
      .catch(() => {
      })
    
    }
  },
  
  created(){
    this.$store.dispatch('semuapesan')
    .then(()=>{
        
    }).catch(() => {
    })
  },
  mounted(){
    this.getUser()
    this.setMenuProfil()
    this.cekdatapesan()
    // this.getDataPesan()
  },
  watch: {
    '$route' (){
      this.setMenuProfil()
    },
    menuProfil(value){
      if(value != this.databarulink){
       this.databarulink = value
         this.cekdatapesan()
      }
    },
    // dataPesan : function(){
    //     this.jumlahChatBaru = 0

    //     this.dataPesan.forEach(element => {
    //         if(element.is_user){
    //             this.jumlahChatBaru = this.jumlahChatBaru + 1
    //         }else{
    //             this.jumlahChatBaru = 0
    //         }
    //     });
    // }
  },
}
</script>

