<template>
    <div>
        <skeleton-ulasan v-if="loading"></skeleton-ulasan>
        <div class="" v-else-if="!loading">
            <div v-if="datatransaksi.length > 1">
                <AddUlasan
                @datareload="datareload"
                :datatransaksi="datatransaksi"></AddUlasan>
            </div>
            <div v-else-if="datatransaksi.length <= 0">
            </div>
            <div v-else-if="datatransaksi">
                    <AddUlasan
                    @datareload="datareload"
                    :datatransaksi="datatransaksi"></AddUlasan>
            </div>
            <div v-if="cekdata">
                <div class="p-md-5">
                    <img src="../../assets/img/undraw_reviews_lp8w.png" class="img-none" alt="">
                </div>
                <p class="w-100 mt-3" style="text-align:center;">Anda belum memilliki Ulasan</p>
            </div>
            <div v-else-if="datareview">
                <div v-for="(ulasandata,index) in datareview" :key="index">
                    <div v-if="ulasandata.review">
                        <h5 class="mt-3">Produk {{index+1}}</h5>
                        <DetailUlasan
                        :image_productdata="ulasandata.image"
                        :name_productdata="ulasandata.name_product"
                        :stardata="ulasandata?.review?.star"
                        :notesdata="ulasandata?.review?.notes"
                        :respon_notesdata="ulasandata?.review?.respon_notes"
                        :imagedata="ulasandata?.review?.image"
                        >

                        </DetailUlasan>
                    </div>
                </div>
            </div>
            <div v-else-if="datareview.length > 0">
                <div v-for="(ulasandata,index) in datareview" :key="index">
                    <div v-if="ulasandata.review">
                        <h5 class="mt-3">Produk {{index+1}}</h5>
                        <DetailUlasan
                        :image_productdata="ulasandata.image"
                        :name_productdata="ulasandata.name_product"
                        :stardata="ulasandata?.review?.star"
                        :notesdata="ulasandata?.review?.notes"
                        :respon_notesdata="ulasandata?.review?.respon_notes"
                        :imagedata="ulasandata?.review?.image"
                        >

                        </DetailUlasan>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import AddUlasan from './AddUlasan.vue'
import axios from 'axios'
import skeletonUlasan from '../SkeletonUser/ulasan/UlasanSkeleton.vue'
import DetailUlasan from './DetailUlasan.vue'
export default {
    data(){
        return{
            datatransaksi:{},
            loading:true,
            cekdata:true,
            datareview:[]
        }
    },
    components:{
        AddUlasan,
        skeletonUlasan,
        DetailUlasan
    },
    created() {
      this.transaksi_id = this.$router.currentRoute._value.query.id_transaksi
        axios.get('/transaction/'+this.transaksi_id).then(res => {
            this.datatransaksi = res.data.data.reviews
                this.loading=false
        })
        .catch(() =>{
            this.loading=false
        })  
        this.transaksi_id = this.$router.currentRoute._value.query.id_transaksi
        axios.get('/review/'+this.transaksi_id).then(res => {
            let datareviews = res.data.data
            let k = 0;
            for(let i = 0;i< datareviews?.transaction_detail.length;i++){
                if(datareviews.transaction_detail[k]?.review){
                    this.datareview.push(datareviews.transaction_detail[k])
                    this.cekdata = false
                }
                k++
            }
            // this.$emit('datareload', datasupport)
            // this.setModal(false, this.modalTipe)
                // this.loading=false
        })
        .catch(() =>{
            // this.loading=false
        }) 
    },
    methods:{
        datareload(value){
            this.loading=true
            this.datareview = value.datareview
            this.datatransaksi = value.datatransaksi
             this.loading=false

        }
    }
}
</script>