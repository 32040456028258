<template>
    <div>
        <pre>{{datatransaksi.complaint}}</pre>
        <skeletonComplain v-if="loading"></skeletonComplain>
        <div class="" v-else-if="!loading">
            <div v-if="datatransaksi.length > 1">
                    <AddComplain
                    @datareload="datareload"
                    :datatransaksi="datatransaksi"></AddComplain>
            </div>
            <div v-else-if="datatransaksi.length <= 0">
            </div>
            <div v-else-if="datatransaksi">
                    <AddComplain
                    @datareload="datareload"
                    :datatransaksi="datatransaksi"></AddComplain>
            </div>
            <div v-if="cekdata">
                <div class="p-md-5">
                    <img src="../../assets/img/undraw_reviews_lp8w.png" class="img-none" alt="">
                </div>
                <p class="w-100 mt-3" style="text-align:center;">Anda belum memilliki Komplain</p>
            </div>
            <div v-else-if="datacomplain">
                <div class="container-fluid" v-for="(complaindata,index) in datacomplain" :key="index">
                    <div v-if="complaindata.complaint">
                        <h4 class="mt-3">Komplain Produk {{index+1}}</h4>
                        <DetailComplaint 
                        :dataimage="complaindata.image"
                        :dataname_product="complaindata.name_product"
                        :datakomplain="complaindata.complaint"
                        ></DetailComplaint>
                    </div>
                </div>
            </div>
            <div v-else-if="datacomplain.length > 0">
                <div class="container-fluid" v-for="(complaindata,index) in datacomplain" :key="index">
                    <div v-if="complaindata.complaint">
                        <h4 class="mt-3">Komplain Produk {{index+1}}</h4>
                        <DetailComplaint 
                        :dataimage="complaindata.image"
                        :dataname_product="complaindata.name_product"
                        :datakomplain="complaindata.complaint"
                        ></DetailComplaint>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import AddComplain from './AddComplain.vue'
import DetailComplaint from './DetailComplain.vue'
import axios from 'axios'
import skeletonComplain from '../SkeletonUser/complain/DetailSkeleton.vue'
export default {
    data(){
        return{
            datatransaksi:{},
            datacomplain:[],
            loading:true,
            cekdata:true
        }
    },
    components:{
        AddComplain,
        skeletonComplain,
        DetailComplaint
    },
    created() {
      this.transaksi_id = this.$router.currentRoute._value.query.id_transaksi
        axios.get('/transaction/'+this.transaksi_id).then(res => {
            this.datatransaksi = res.data.data.complaint
            console.log(this.datatransaksi)
            console.log(this.datatransaksi.length)
                this.loading=false
        })
        .catch(() =>{
            this.loading=false
        })  
        axios.get('/transaction/complaint/'+this.transaksi_id).then(res => {
            let Komplaindata = res.data.data
            console.log(Komplaindata)
            let k = 0;
            for(let i = 0;i< Komplaindata?.transaction_detail.length;i++){
                if(Komplaindata.transaction_detail[k].complaint){
                    console.log(Komplaindata.transaction_detail[k])
                    this.datacomplain.push(Komplaindata.transaction_detail[k])
                    this.cekdata = false
                }
                k++
            }
        })
        .catch(() =>{
            // this.loading=false
        })  
    },
    methods:{
        datareload(value){
             this.loading=true
            this.datacomplain = []
            let complaindata = value.datacomplaint
            let k = 0;
            for(let i = 0;i< complaindata?.transaction_detail.length;i++){
                if(complaindata.transaction_detail[k].complaint){
                    this.datacomplain.push(complaindata.transaction_detail[k])
                    this.cekdata = false
                }
                k++
            }
            this.datatransaksi = value.datatransaksi
            this.loading=false 
        },
    }
}
</script>