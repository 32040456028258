<template>
    <div class="container-fluid h-100">
        <div class="row h-100">
            <div class="col-md-4 col-lg-4 h-100 data-left">
                <div class="isi-data-left">
                    <img :src="baseUrl+'storage/'+image" alt="">
                </div>
            </div>
            <div class="col-md-8 col-lg-8 data-right">
                <div class=" m-2 m-md-5">
                    <router-link to="/" class="d-block text-center font-bold custom-link logo-container auth-logo-container" style="font-size:24px;">
                        <!-- <img :src="require(`../../assets/DSN-logo.png`)" alt="" class="logo"> -->
                        <logo></logo>
                    </router-link>
                    
                    <div class="header-text text-center">
                        <h1 class="mt-3"><b>Registrasi</b></h1>
                        <p>Anda sudah memiliki akun BlanjaSmart? <router-link to="/login" class="login">Klik Disini</router-link></p>
                    </div>
                    <form class="form-regis" @submit.prevent="registrasi">
                        <div class="row">
                            <div class="col-md mt-4">
                                <label for="username">Username</label>
                                <input id="username" type="text" class="form-control mt-1 radius" v-model="username" placeholder="Username"
                                :class="[v$.username.$error ? 'is-invalid' : '']">
                                <span v-if="v$.username.$error" class="text-danger font-small pesan-error"> {{v$.username.$errors[0].$message}} </span>
                            </div>
                            <div class="col-md mt-4">
                                <label for="name">Nama</label>
                                <input id="name" type="name" class="form-control mt-1 radius" v-model="name" placeholder="Nama"
                                :class="[(v$.name.$error || name.length >50) ? 'is-invalid' : '']">
                                <span v-if="v$.name.$error" class="text-danger font-small pesan-error">{{v$.name.$errors[0].$message}}</span>
                                <span v-if="name.length >50" class="text-danger font-small pesan-error">Nama terlalu panjang!</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md mt-4">
                                <label for="instance_name">Nama Instansi</label>
                                <input id="instance_name" type="text" class="form-control mt-1 radius" v-model="instance_name" placeholder="Nama Instansi"
                                :class="[(v$.instance_name.$error || instance_name.length >50) ? 'is-invalid' : '']"
                                >
                                <span v-if="v$.instance_name.$error" class="text-danger font-small pesan-error">{{v$.instance_name.$errors[0].$message}}</span>
                                <span v-if="instance_name.length >50" class="text-danger font-small pesan-error">Nama Instansi terlalu panjang!</span>
                            </div>
                            <div class="col-md mt-4">
                                <label for="email">Email</label>
                                <input id="email" type="text" class="form-control mt-1 radius" v-model="email" placeholder="Email"
                                :class="[v$.email.$error ? 'is-invalid' : '']"
                                >
                                <span v-if="v$.email.$error" class="text-danger font-small pesan-error">{{v$.email.$errors[0].$message}}</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md mt-4">
                            <label for="phone">Telepon</label>
                            <input id="phone" type="text" maxlength="15" class="form-control mt-1 radius" v-model="phone" placeholder="Telepon"
                            :class="[(v$.phone.$error || phone.length >15) ? 'is-invalid' : '']"
                            >
                            <span v-if="v$.phone.$error" class="text-danger font-small">{{v$.phone.$errors[0].$message}}</span>
                            </div>
                            <div class="col-md mt-4">
                            <label for="birthdate">Tanggal Lahir</label>
                            <input id="birthdate" type="date" class="form-control mt-1 border-lightdata" v-model="birthdate" placeholder="Tanggal Lahir"
                            :class="[v$.birthdate.$error ? 'is-invalid' : '']"
                            >
                            <span v-if="v$.birthdate.$error" class="text-danger font-small pesan-error">{{v$.birthdate.$errors[0].$message}}</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md mt-4">
                            <label for="lspe" data-bs-toggle="popover" title="Layanan Pengadaan Secara Elektronik">
                                LPSE
                                <i class="bx bxs-info-circle"></i>
                                </label>
                                <input id="lspe" type="text" class="form-control mt-1 radius" v-model="lspe" placeholder="LPSE"
                                :class="[v$.lspe.$error ? 'is-invalid' : '']"
                                >
                                <span v-if="v$.lspe.$error" class="text-danger font-small pesan-error">{{v$.lspe.$errors[0].$message}}</span>

                            </div>
                            <div class="col-md mt-4">
                            <label for="kldi" data-bs-toggle="popover" title="Kementrian/ Lembaga/ Satuan Kerja Perangkat Daerah/ Institusi Lainnya">
                                KLDI                                
                                <i class="bx bxs-info-circle" ></i>
                            </label>
                            <input id="kldi" type="text" class="form-control mt-1 border-lightdata" v-model="kldi" placeholder="KLDI"
                            :class="[v$.kldi.$error ? 'is-invalid' : '']"
                            >
                            <span v-if="v$.kldi.$error" class="text-danger font-small pesan-error">{{v$.kldi.$errors[0].$message}}</span>

                            </div>
                        </div>
                        <div class="form-group mt-4">
                            <label for="laki-laki">Jenis Kelamin</label>
                            <div class="radio-btn mt-1">
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="laki-laki" value="1" name="gender" class="custom-control-input"  v-model="gender"
                                    :class="[v$.gender.$error ? 'is-invalid' : '']"
                                    >
                                    <label class="custom-control-label" for="laki-laki" style="margin-left:5px;">Laki-Laki</label>
                                </div>
                                <div class="custom-control custom-radio custom-control-inline" style="margin-left:10px;">
                                    <input type="radio" id="perempuan" value="0" name="gender" class="ml-2 custom-control-input" v-model="gender"
                                    :class="[v$.gender.$error ? 'is-invalid' : '']"
                                    >
                                    <label class="custom-control-label" style="margin-left:5px;" for="perempuan">Perempuan</label>
                                </div>
                            </div>
                            <span v-if="v$.gender.$error" class="text-danger font-small pesan-error">{{v$.gender.$errors[0].$message}}</span>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-md mt-4">
                                <label for="province_id">Provinsi</label>
                                <select id="province_id" class="form-control mt-1 radius" v-model="province_id" @change="getWilayah('district',$event)"
                                :class="[v$.province_id.$error ? 'is-invalid' : '']"
                                >
                                    <option value="" disabled>Pilih Provinsi</option>
                                    <option :value="provinsi.province_id" v-for="provinsi in provinces" :key="provinsi.province_id">{{provinsi.province_name}}</option>
                                </select>
                                <span v-if="v$.province_id.$error" class="text-danger font-small pesan-error">{{v$.province_id.$errors[0].$message}}</span>
                            </div>
                            <div class="col-md mt-4">
                                <label for="district_id">Kabupaten</label>
                                <select id="district_id" class="form-control mt-1 radius" v-model="district_id" @change="getWilayah('sub-district',$event)"
                                :class="[v$.district_id.$error ? 'is-invalid' : '']"
                                >
                                    <option value="" disabled>Pilih Kabupaten</option>
                                    <option :value="district.city_id" v-for="district in districts" :key="district.city_id">{{district.city_name}}</option>
                                </select>
                                <span v-if="v$.district_id.$error" class="text-danger font-small pesan-error">{{v$.district_id.$errors[0].$message}}</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md mt-4">
                                <label for="sub_district_id">Kecamatan</label>
                                <select id="sub_district_id" class="form-control mt-1 radius" v-model="sub_district_id" 
                                :class="[v$.sub_district_id.$error ? 'is-invalid' : '']"
                                >
                                    <option value="" disabled>Pilih Kecamatan</option>
                                    <option :value="sub_district.subdistrict_id" v-for="sub_district in sub_districts" :key="sub_district.sub_districtid">{{sub_district.subdistrict_name}}</option>
                                </select>
                                <span v-if="v$.sub_district_id.$error" class="text-danger font-small pesan-error">{{v$.sub_district_id.$errors[0].$message}}</span>
                            </div>
                        </div>
                        <div class="mt-3">
                            <label for="address">Alamat</label>
                            <textarea name="address" id="address" rows="3" class="form-control w-100 mt-1 border-lightdata" v-model="address"
                            :class="[(v$.address.$error || address.length >500) ? 'is-invalid' : '']"
                            ></textarea>
                            <span v-if="v$.address.$error" class="text-danger font-small pesan-error">{{v$.address.$errors[0].$message}}</span>
                            <span v-if="address.length >500" class="text-danger font-small pesan-error">Alamat terlalu panjang!</span>

                        </div>
                        <div class="row">
                            <div class="col-md mt-4">
                                <label for="nip">NIP</label>
                                <input id="nip" type="text" maxlength="201" class="form-control mt-1 radius" placeholder="NIP" v-model="nip"
                                :class="[(v$.nip.$error || nip.length>200) ? 'is-invalid' : '']"
                                >
                                <span v-if="v$.nip.$error" class="text-danger font-small pesan-error">{{v$.nip.$errors[0].$message}}</span>
                                <span v-if="nip.length >200" class="text-danger font-small pesan-error">NIP terlalu panjang!</span>

                            </div>
                            <div class="col-md mt-4">
                                <label for="name_pp">Nama PP</label>
                                <input id="name_pp" type="text" maxlength="201" class="form-control mt-1 radius" placeholder="Nama PP" v-model="name_pp"
                                :class="[(v$.name_pp.$error || name_pp.length >200) ? 'is-invalid' : '']"
                                >
                                <span v-if="v$.name_pp.$error" class="text-danger font-small pesan-error">{{v$.name_pp.$errors[0].$message}}</span>
                                <span v-if="name_pp.length >200" class="text-danger font-small pesan-error">Nama PP terlalu panjang!</span>

                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group mt-4">
                                <label for="name_ppk">Nama PPK</label>
                                <input type="text" maxlength="201" class="form-control radius" id="name_ppk" placeholder="Nama PPK" v-model="name_ppk"
                                :class="[(v$.name_ppk.$error || name_ppk.length >200) ? 'is-invalid' : '']"
                                >
                                <span v-if="v$.name_ppk.$error" class="text-danger font-small pesan-error">{{v$.name_ppk.$errors[0].$message}}</span>
                                <span v-if="name_ppk.length >200" class="text-danger font-small pesan-error">Nama PPK terlalu panjang!</span>


                            </div>
                            <div class="form-group mt-4">
                                <label for="satker">Satuan Kerja</label>
                                <input type="text" class="form-control radius" id="satker" placeholder="Satuan Kerja" v-model="satker"
                                :class="[v$.satker.$error ? 'is-invalid' : '']"
                                >
                                <span v-if="v$.satker.$error" class="text-danger font-small pesan-error">{{v$.satker.$errors[0].$message}}</span>

                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md form-group mt-4">
                                <label for="receipt_name">Nama Penerima</label>
                                <input type="text" class="form-control radius" id="receipt_name" placeholder="Nama Penerima" v-model="receipt_name"
                                :class="[v$.receipt_name.$error ? 'is-invalid' : '']"
                                >
                                <span v-if="v$.receipt_name.$error" class="text-danger font-small pesan-error">{{v$.receipt_name.$errors[0].$message}}</span>

                            </div>
                            <div class="col-md form-group mt-4">
                                <label for="receipt_phone_number">No Telepon Penerima</label>
                                <input type="text" class="form-control radius" id="receipt_phone_number" min="0" autocomplete="false" placeholder="No Telepon Penerima" v-model="receipt_phone_number"
                                :class="[v$.receipt_phone_number.$error ? 'is-invalid' : '']"
                                >
                                <span v-if="v$.receipt_phone_number.$error" class="text-danger font-small pesan-error">{{v$.receipt_phone_number.$errors[0].$message}}</span>

                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md mt-4">
                            <label for="password">Kata Sandi</label>
                            <div class="position-relative password-input">
                                <input id="password" type="password" class="form-control mt-1 radius" autocomplete="false" placeholder="Kata Sandi" v-model="password"
                                :class="[v$.password.$error ? 'is-invalid' : '']"
                                >
                                <div  v-if="!v$.password.$error" class="icon-container" @click="togglePass('password')">
                                    <i class='bx bx-hide'></i>
                                </div>
                            </div>
                            <span v-if="v$.password.$error" class="text-danger font-small pesan-error">{{v$.password.$errors[0].$message}}</span>

                            </div>
                            <div class="col-md mt-4">
                            <label for="confirmpassword">Konfirmasi Kata Sandi</label>
                            <div class="position-relative password-input">
                                <input id="confirmpassword" type="password" class="form-control mt-1 radius" autocomplete="false" placeholder="Konfirmasi Kata Sandi" v-model="password_confirmation"
                                :class="[v$.password_confirmation.$error ? 'is-invalid' : '']"
                                >
                                <div v-if="!v$.password_confirmation.$error" class="icon-container" @click="togglePass('confirmpassword')">
                                    <i class='bx bx-hide'></i>
                                </div>
                            </div>
                            <span v-if="v$.password_confirmation.$error" class="text-danger font-small pesan-error">{{v$.password_confirmation.$errors[0].$message}}</span>

                            </div>
                        </div>
                        <div class="form-group mt-4">
                            <input class="form-check-input" type="checkbox" value="1" id="checkbox" v-model="setuju">
                            <label class="form-check-label label-syarat" for="checkbox" style="margin-left:5px;" >
                                Saya Menyetujui <span class="fw-bold" @click="setModal(true, 'syarat')" data-bs-toggle="modal" data-bs-target="#staticBackdrop">syarat dan ketentuan</span> yang berlaku
                            </label>
                        </div>

                        <div v-if="error" class="alert alert-warning alert-dismissible fade show mt-4"  role="alert">
                            <strong>Peringatan !</strong> 
                            <span> Pastikan Anda sudah membaca dan menyetujui syarat dan ketentuan yang berlaku. </span>
                            
                            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                        </div>

                        <div v-if="errors.username != '' || errors.email != ''" class="alert alert-warning alert-dismissible fade show mt-4"  role="alert">
                            <strong>Peringatan ! </strong> 
                            <span v-if="errors.username != '' && errors.email == ''">{{errors.username}}</span>
                            <span v-if="errors.email != '' && errors.username == ''">Email sudah terdaftar silahkan gunakan yang lain</span>
                            <span v-if="errors.username != '' && errors.email != ''">Username dan Email sudah terdaftar silahkan gunakan yang lain</span>
                            
                            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                        </div>

                        <div v-if="success" class="alert alert-success alert-dismissible fade show mt-4"  role="alert">
                            <strong>Berhasil !</strong> 
                            <span> Registrasi berhasil dilakukan, silahkan periksa email anda, untuk melakukan verifikasi email. </span>
                        </div>
                        <div class="container-fluid mt-4 mb-5">
                            <div style="float: right;height:80px;">
                                <button type="submit" class="btn btn-red btn-daftar">Daftar</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <div v-if="loadingSubmit" class="loading-submit-animation-container" >
            <div class="loading-submit-animation-content">
                <div>
                    <div class="lds-facebook"><div></div><div></div><div></div></div>
                    <p>Mohon tunggu, data sedang diproses</p>
                </div>
            </div>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title text-center" id="staticBackdropLabel">Syarat & Ketentuan</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div :class="{ 'loading-skeleton' : loading }" v-if="loading">
                        <p>Loading..</p>
                    </div>
                    <p class="" v-html="term.tac"></p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <!-- <button type="button" class="btn btn-danger">Understood</button> -->
                </div>
                </div>
            </div>
        </div>

        <Modal
          :show="modal.showModal"
          :header="modal.header"
          :headerBorder="modal.headerBorder"
          :title="modal.title"
          :titleText="modal.titleText"
          :footer="modal.footer"
          :footerBtnPosition="modal.footerBtnPosition"
          :footerBorder="modal.footerBorder"
          :btnCancel="modal.btnCancel"
          :btnSubmit="modal.btnSubmit"
          :btnSubmitText="modal.btnSubmitText"
          :btnCancelText="modal.btnCancelText"
          :btnSubmitWidth="modal.btnSubmitWidth"
          :btnCancelWidth="modal.btnCancelWidth"
          @closeModal="closeModal"
          @submitModal="submitModal"
        >

          <!-- Syarat & Ketentuan -->
          <div>
            <div v-if="modalTipe === 'syarat'" class="negosisasi-container">
              <p>
                  modal syarat
              </p>
            </div>
          </div>
        </Modal>
        
    </div>
</template>

<script>
import axios from "axios";
import useVuelidate from '@vuelidate/core'
import { required, email, helpers, minLength, sameAs, numeric  } from '@vuelidate/validators'
import Modal from '../../components/Modal.vue'
import Logo from "@/components/Logo.vue";


export default {
    name: 'register-component',
    props:['banners'],
    setup () {
        return { v$: useVuelidate() }
    },
    validations() {
        return {
            username: {
                required: helpers.withMessage("Username tidak boleh kosong", required),
            },
            name: {
                required: helpers.withMessage("Nama tidak boleh kosong", required),
            },
            instance_name: {
                required: helpers.withMessage("Nama instansi tidak boleh kosong", required),
            },
            email: {
                required: helpers.withMessage("Email tidak boleh kosong", required),
                email: helpers.withMessage("Format email salah", email)
            },
            password: {
                required: helpers.withMessage("Kata Sandi tidak boleh kosong", required), 
                min: helpers.withMessage(
                    ({
                        $params
                    }) => `Kata Sandi minimal memiliki ${$params.min} karakter `,
                    minLength(8)
                )
            },
            password_confirmation: {
                required: helpers.withMessage("Konfirmasi kata sandi tidak boleh kosong", required), 
                sameAs: helpers.withMessage(`Konfirmasi kata sandi tidak sama dengan password `,sameAs(this.password))
            },
            phone: {
                required: helpers.withMessage("No telepon tidak boleh kosong", required),
                numeric: helpers.withMessage("Pastikan data yang diinput berupa angka", numeric)
            },
            birthdate: {
                required: helpers.withMessage("Tanggal lahir tidak boleh kosong", required),
            },
            lspe: {
                required: helpers.withMessage("LPSE tidak boleh kosong", required),
                numeric: helpers.withMessage("Pastikan data yang diinput berupa angka", numeric)
            },
            kldi: {
                required: helpers.withMessage("KLDI tidak boleh kosong", required),
            },
            gender: {
                required: helpers.withMessage("Jenis kelamin tidak boleh kosong", required),
            },
            province_id: {
                required: helpers.withMessage("Provinsi tidak boleh kosong", required),
            },
            district_id: {
                required: helpers.withMessage("Kabupaten tidak boleh kosong", required),
            },
            sub_district_id: {
                required: helpers.withMessage("Kecamatan tidak boleh kosong", required),
            },
            address: {
                required: helpers.withMessage("Alamat tidak boleh kosong", required),
            },
            nip: {
                required: helpers.withMessage("NIP tidak boleh kosong", required),
                numeric: helpers.withMessage("Pastikan data yang diinput berupa angka", numeric)
            },
            name_pp: {
                required: helpers.withMessage("Nama PP tidak boleh kosong", required),
            },
            name_ppk: {
                required: helpers.withMessage("Nama PPK tidak boleh kosong", required),
            },
            satker: {
                required: helpers.withMessage("Satuan kerja tidak boleh kosong", required),
            },
            receipt_name: {
                required: helpers.withMessage("Nama penerima tidak boleh kosong", required),
            },
            receipt_phone_number: {
                required: helpers.withMessage("No telepon penerima tidak boleh kosong", required),
                numeric: helpers.withMessage("Pastikan data yang diinput berupa angka", numeric)
            },
        }
    },
    data(){
        return{
            username:"",
            name:"",
            instance_name: "",
            email: "",
            password: "",
            password_confirmation: "",
            phone: "",
            birthdate: "",
            lspe:"",
            kldi:"",
            gender: "",
            province_id: "",
            district_id: "",
            sub_district_id: "",
            address: "",
            nip: "",
            term: [],
            loading: false,
            name_pp: "",
            name_ppk: "",
            satker:"",
            receipt_name:"",
            receipt_phone_number:"",
            setuju: "",
            login_redirect_url: '',
            errors: {
                username:'',
                email:''
            },
            provinces:"",
            districts:"",
            sub_districts:"",
            error: false,
            success: false,
            loadingSubmit: false,
            modalTipe: '',
            modal:{
                showModal: false,
                header:false,
                headerBorder: false,
                title: false,
                titleText: '',
                footer: false,
                footerBtnPosition:'',
                footerBorder: false,
                btnCancel: false,
                btnSubmit: false,
                btnSubmitText: '',
                btnCancelText: '',
                btnSubmitWidth: '',
                btnCancelWidth: '',
            },
            image: {},
            baseUrl: process.env.VUE_APP_NAME_AXIOS
        }
        
    },
    components: {
        Modal,
        Logo
    },
    created(){
        this.getWilayah('province');
    },
    methods: {
        getWilayah(wilayah, event){
            wilayah = 'customer/' +wilayah
            let cek_wilayah = wilayah;
            event ? wilayah += '/'+event.target.value : wilayah;
            axios.get(wilayah)
                .then(response => {
                    if(cek_wilayah == 'customer/province'){
                        this.provinces = response.data.data
                    }else if(cek_wilayah == 'customer/district'){
                        this.districts = response.data.data
                    }else if(cek_wilayah == 'customer/sub-district'){
                        this.sub_districts = response.data.data
                    }
                })
                .catch(error => {
                    console.error("There was an error!", error);
                }); 
        },
        togglePass(id){
            if(document.querySelector('#'+id).type == 'password'){
                document.querySelector('#'+id).type = 'text'
                document.querySelector('#'+id+'~.icon-container .bx').classList.remove('bx-hide')
                document.querySelector('#'+id+'~.icon-container .bx').classList.add('bx-show')
            }else {
                document.querySelector('#'+id).type = 'password'
                document.querySelector('#'+id+'~.icon-container .bx').classList.remove('bx-show')
                document.querySelector('#'+id+'~.icon-container .bx').classList.add('bx-hide')
            }
        },
        setImage(){
            if(this.banners != null){
                for(let i = 0; i < Object.keys(this.banners).length; i++){
                    if(this.banners[i].position === 'Registration Page'){
                        this.image = this.banners[i].image
                        // console.log(this.baseUrl+this.loginImage);
                    }
                }
                this.loadingMainBanner = false
            }
        },
        setModal(condition, tipe){
            this.resetDataModal()
            this.modal.showModal= condition
            condition == true ? this.modalTipe = tipe : ''
            if(tipe == 'syarat' && condition){
                this.modal.header=true
                this.modal.headerBorder=true
                this.modal.title=true
                this.modal.titleText='Syarat & Ketentuan'
                this.modal.footer=true
                this.modal.footerBorder=true
                this.modal.footerBtnPosition='right'
                this.modal.btnSubmitText='Oke'
                this.modal.btnSubmitWidth='auto'
                this.modal.btnSubmit=true
            }
        },
        registrasi() {

            this.v$.$validate()
            this.login_redirect_url = window.location.origin+'/login'

            let data = new FormData()
            data.append('username', this.username)
            data.append('name', this.name)
            data.append('instance_name', this.instance_name)
            data.append('email', this.email)
            data.append('password', this.password)
            data.append('password_confirmation', this.password_confirmation)
            data.append('phone', this.phone.toString())
            data.append('birthdate', this.birthdate)
            data.append('lspe', this.lspe)
            data.append('kldi', this.kldi)
            data.append('gender', this.gender)
            data.append('province_id', this.province_id)
            data.append('district_id', this.district_id)
            data.append('sub_district_id', this.sub_district_id)
            data.append('address', this.address)
            data.append('nip', this.nip.toString())
            data.append('name_pp', this.name_pp)
            data.append('name_ppk', this.name_ppk)
            data.append('satker', this.satker)
            data.append('receipt_name', this.receipt_name)
            data.append('receipt_phone_number', this.receipt_phone_number.toString())
            data.append('setuju', this.setuju)
            data.append('login_redirect_url', this.login_redirect_url)
            this.resetErrorForm()
            
            if(this.setuju){
                if ( !this.v$.$error) {
                    this.error = false
                    this.loadingSubmit =  true
                    axios.post('customer/create', data)
                    .then(() => {
                        this.success = true
                        // console.log(response)
                        this.loadingSubmit =  false

                    })
                    .catch(error => {
                        this.loadingSubmit =  false
                        let data_error = Object.entries(error.response.data.data)
                        for(let i = 0; i < data_error.length; i++){
                            this.errors[data_error[i][0]] = data_error[i][1][0];
                        }
                        window.scroll({
                            top: 0,
                            left: 0,
                            behavior: 'smooth'
                        });
                    }); 
                }
            }else{
                this.success = false
                this.error = true; 
            }

        },
        resetForm(){
            this.username = "";
            this.name = "";
            this.instance_name = "";
            this.email = "";
            this.password = "";
            this.password_confirmation = "";
            this.phone =  "";
            this.birthdate =  "";
            this.lspe ="";
            this.kldi = "";
            this.gender = "";
            this.province_id = "";
            this.district_id =  "";
            this.sub_district_id =  "";
            this.address = "";
            this.nip = "";
            this.name_pp = "";
            this.name_ppk = "";
            this.satker = "";
            this.receipt_name = "";
            this.receipt_phone_number ="";
            this.setuju = "";
            this.login_redirect_url= '';
        },
        resetErrorForm(){
            this.errors.email = ''
            this.errors.username = ''
        }
        
    },
    mounted(){
        this.loading = true
        this.setImage()
        axios.get(`setting`)
        .then((result) => {
            console.log('term : ', result.data.data)
            this.term = result.data.data
            this.loading = false
        }).catch((err) => {
            console.log(err.response);
            this.loading = false
        })
    },
    watch:{
        banners:{
            deep: true,
            handler: function(){
                this.setImage()
                //           :autoplay='{
                // "delay": 2500,
                // "disableOnInteraction": false
                // }'
            }
        },
    }
}
</script>



<style scoped>
.password-input .icon-container{
    position:  absolute;
    top:0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items:center;
    padding: 1rem;
}

.password-input .icon-container:hover{
    cursor:pointer;
}

.password-input input[type="password"]{
    padding-right: 2.5rem;
}

.login{
    text-decoration: none;
    font-weight: bold;
    color: #000;
}
.form-regis{
    margin: 0 auto;
}
.data-left{
    display: none;
    /* background-image: url("../../assets/img/ousa-chea-gKUC4TMhOiY-unsplash.webp"); */
    background-size: cover;
    background-repeat: no-repeat;
    padding: 0;
}
.data-left img{
    object-fit: cover;
    width: 100%;
    height: 100%;
}
.radius{
    border-radius: 25px;
    padding-left: 15px;
}
.border-lightdata{
    border-radius: 15px;
    /* padding-left: 15px; */
}
.isi-data-left{
height: 100vh;
}
.data-right{
    overflow: auto;
    height: 100vh;
}
.radio-btn{
    display: flex;
}
.label-syarat{
    font-size: 0.6rem;
}
.btn-daftar{
    padding: 5px 30px;
    border-radius: 25px;
}
@media (min-width: 319px) {
}

@media (min-width: 374px) {
}

@media (min-width: 424px) {
}

@media (min-width: 767px) {
    .data-left{
        display: block;
    }
    .form-regis{
        width: 100%;
    }
    .label-syarat{
        font-size: 0.8rem;
    }
}

@media (min-width: 1023px) {
    .form-regis{
        width: 95%;
    }
    .label-syarat{
        font-size: 1rem;
    }
}

@media (min-width: 1439px) {
    .form-regis{
        width: 75%;
    }
}

@media (min-width: 2000px) {
}
</style>
