<template>
  <div id="app">
    <div>
      <div :duration="{ enter: 1000, leave: 200 }" v-if="!loading" class="loading-transition">
        <div class="loading-container-animation">
          <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
        </div>
      </div>
      <Sidebar v-if="showBar"
      :dataCategories="dataCategories"
      :dataSubCategories="dataSubCategories"
      :supportWebp="supportWebp"
      ></Sidebar>
      <Navbar v-if="showBar" 
      :user="user"
      :dataCarts="dataCarts"
      :sticky="sticky"
      :supportWebp="supportWebp"
      ></Navbar>

      <router-view :user="user"
      :dataAllProducts="dataAllProducts"
      :dataCategories="dataCategories"
      :dataSubCategories="dataSubCategories"
      :dataCarts="dataCarts"
      :banners="banners"
      :supportWebp="supportWebp"
      @setLoadingTransition="setLoadingTransition"

      ></router-view>
      <Footer v-if="showBar"></Footer>
      <Footbar  v-if="showBar"></Footbar>
      <button id="btnToTop" class="btn btn-red" @click="scrollToTop"><i class='bx bx-chevron-up'></i></button>
    </div>
  </div>
</template>

<script>

import Navbar from './components/Navbar.vue'
import Sidebar from './components/Sidebar.vue'
import Footer from './components/Footer.vue'
import Footbar from './components/Footbar.vue'

export default {
  name: 'App',
  data(){
    return{
      showBar:false,
      dataAllProducts:[],
      dataCategories: [],
      dataSubCategories: [],
      dataCarts:'',
      cartsloaded:false,
      banner:[],
      loading:true,
      sticky: false,
      supportWebp:false,
      baseUrl: process.env.VUE_APP_NAME_AXIOS,
    }
  },
  components: {
    Navbar,
    Sidebar,
    Footer,
    Footbar,
  },

  methods:{
    scrollToTop(){
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    },
    check_router(route){
      const route_auth = ['/login', '/register','/lupa-password','/reset-password']
      let auth = false
      for(let i = 0; i < route_auth.length; i++){
        if(route_auth[i] == route){
          auth = true;
          break;
        }
      }
      return auth;
    },
    setLoadingTransition(param){
      this.loading = param
    },
    handleScroll(){
      window.document.onscroll = () => {
        if(window.scrollY > 0){
          this.sticky = true
        } else {
          this.sticky = false
        }
      }
    },
    checkSupportWebp(){
      let elem = document.createElement('canvas');
      if ((elem.getContext && elem.getContext('2d')))
      {
        this.supportWebp = elem.toDataURL('image/webp').indexOf('data:image/webp') == 0
      }else{
        this.supportWebp = ''
      }
    },
    setButtonTop(){
      // button scroll to top
      window.document.onscroll = () => {
        let btnToTop = document.querySelector('#btnToTop');
        if(window.scrollY > 10){
          if(btnToTop.classList.contains('d-none')){
            btnToTop.classList.remove('d-none')
          }
        } else {
          if(! btnToTop.classList.contains('d-none')){
            btnToTop.classList.add('d-none')
          }
        }
      }
    }

  },
  watch: {
    '$route' (){
      // scroll to top
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });      
      if(this.$cookies.isKey('token')){

        // this.$store.dispatch('user')
        if(this.check_router(this.$router.currentRoute._value.path)){
          this.$router.push('/')
        }

        this.showBar = true
      }else{
        if(this.check_router(this.$router.currentRoute._value.path)){
          this.showBar = false
        }else{
          this.showBar = true
        } 
      }
    },
    products:{
      deep: true,
      handler: function(){
        this.dataAllProducts = Object.values(this.$store.state.products)
      }
    },
    categories:{
      deep: true,
      handler: function(){
        this.dataCategories = Object.values(this.$store.state.categories)
      }
    },
    subcategories:{
      deep: true,
      handler: function(){
        this.dataSubCategories =  Object.values(this.$store.state.subcategories)
      }
    },
    carts:{
      deep: true,
      handler: function(){
        this.dataCarts =  Object.values(this.$store.state.carts)
      }
    },
    banners:{
      deep: true,
      handler: function(){
        this.banners =  Object.values(this.$store.state.banners)
      }
    },
    user(value){
      if(!(value instanceof Promise) && value !== null && this.cartsloaded == false){
          let me = this
          this.$store.dispatch('address').then(()=>{
            me.cartsloaded = true
          })
          
        }
    },
  },
  mounted(){
    this.$store.dispatch('user')
    this.checkSupportWebp()
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("scroll", this.setButtonTop);

    // if(this.$cookies.isKey('token')){
    //   this.$store.dispatch('user')
    // }
    this.$store.dispatch('products')
    this.$store.dispatch('categories')
    this.$store.dispatch('subcategories')
    this.$store.dispatch('banners')

    // set slice data rekomendasi di home
    this.$cookies.set('dariTampilRekomendasi', 0);
    this.$cookies.set('jumlahTampilRekomendasi', 20);
    this.$cookies.set('sampaiTampilRekomendasi', 20);

  },
  // mounted(){
    // this.$store.dispatch('user')
    // this.$store.dispatch('products')
  // },
  computed: {
    user: function() {
      return this.$store.state.user;
    },
    products: function() {
        return this.$store.state.products;
    },
    categories: function() {
        return this.$store.state.categories;
    },
    subcategories: function() {
        return this.$store.state.subcategories;
    },
    carts: function() {
        return this.$store.state.carts;
    },
    banners: function(){
        return this.$store.state.banners;
    }
  }
}
</script>

<style>
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}



</style>
