<template>
      
      <div class="detail section section-modif">

        <div class="detail-container container">
          <div v-if="Object.keys(productDetail).length > 0 " class="d-flex justify-content-end">
            <nav aria-label="breadcrumb" class="">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><router-link to="/" class="custom-link">Beranda</router-link></li>
                <li v-if="productDetail.subcategory?.category.name" 
                class="breadcrumb-item active"
                data-bs-toggle="popover" :title="productDetail.subcategory?.category.name">
                  <router-link :to="'/produk?kategori='+productDetail.subcategory?.category.id" class="custom-link">{{productDetail.subcategory?.category.name}}</router-link>
                </li>
                <li v-if="productDetail.subcategory?.name" 
                class="breadcrumb-item active"
                data-bs-toggle="popover" :title="productDetail.subcategory?.name">
                  <router-link 
                  :to="'/produk?kategori='+productDetail.subcategory?.category.id+'&sub_kategori='+productDetail.subcategory?.id" 
                  class="custom-link">{{productDetail.subcategory?.name}}</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page"
                data-bs-toggle="popover" :title="productDetail.name"
                >{{productDetail.name}}</li>
              </ol>
            </nav>
          </div>

          <div class="product-content d-flex justify-content-center align-items-center" v-if="loadingProduct" >
            <div class="lds-facebook"><div></div><div></div><div></div></div>
          </div> 
          <div v-if="!loadingProduct && Object.keys(productDetail).length > 0 " class="detail-content-container">
            <div class="detail-top-container">
              <div class="detail-image-container">
                <div class="detail-thumb-container">
                  <ul v-if="supportWebp && productImage != ''" class="detail-thumb-list">
                    <li v-for="(thumb, index) in imageProductDetail" :key="index" class="detail-thumb-item" :class="{'thumb-active': thumbActive == index}" 
                    :style="thumb.webp === null ? 'background-image: url('+baseUrl+''+thumb.image +')' : 'background-image: url('+baseUrl+''+thumb.webp +')'" @click="setProductImage(thumb.webp === null ? baseUrl+''+thumb.image : baseUrl+''+thumb.webp , index)"></li>
                  </ul>
                  <ul v-else-if="!supportWebp && productImage != ''" class="detail-thumb-list">
                    <li v-for="(thumb, index) in imageProductDetail" :key="index" class="detail-thumb-item" :class="{'thumb-active': thumbActive == index}" :style="'background-image:url('+baseUrl+''+thumb.image+')'" @click="setProductImage(baseUrl+''+thumb.image, index)"></li>
                  </ul>
                  <ul v-else class="detail-thumb-list">
                    <li class="detail-thumb-item thumb-active d-flex align-items-center justify-content-center">
                      <i class='bx bxs-image'></i>
                    </li>
                  </ul>
                </div>
                <div v-if="productImage != ''" class="detail-preview-container" :style="'background-image:url('+productImage+')'" @click="showPreview(productImage)"></div>
                <div v-else class="detail-preview-container d-flex align-items-center justify-content-center"><i class='bx bxs-image font-xtra-large' ></i></div>
              </div>
              <div v-if="productDetail.name" class="detail-info-container">
                  <h5 class="detail-info-title mb-0">{{productDetail.name}}</h5>
                  <ul  class="detail-info-ulasan">
                    <li>
                      <i class='bx bxs-star text-warning'></i>
                      <span>{{parseFloat(productDetail.star_review).toFixed(1)}} ({{parseInt(productDetail.count_review)}} ulasan)</span>
                    </li>
                    <li>{{productDetail.total_sold}} terjual</li>
                  </ul>
                  <hr class="border border-2">
                  <div v-if="jumlahPromo != 0"  class="detail-info-price-container">
                    <div class="relative d-inline">
                      <div class="detail-info-disc d-inline absolute">Off {{parseInt(productDetail.promo[0].discount)}}%</div>
                      <div class="detail-info-price mt-n3">Rp. {{ new Intl.NumberFormat('id-ID').format(parseInt(productDetail.promo[0].price_promo).toFixed()) }}</div>
                      <div class="line-through">Rp. {{ new Intl.NumberFormat('id-ID').format(parseInt(productDetail.price).toFixed()) }}</div>
                    </div>
                  </div>  

                  <div v-else class="detail-info-price-container">
                    <div class="detail-info-price">Rp. {{ new Intl.NumberFormat('id-ID').format(parseInt(productDetail.price).toFixed()) }}</div>
                  </div>  

                  <div class="input-copy">
                    <div class="notif-copy">Berhasil disalin</div>
                    <div class="input-group w-100" style="flex-wrap:nowrap">
                      <input 
                        style="width:100%"
                        type="text"
                        class="input"
                        v-on:focus="$event.target.select()" 
                        ref="clone" 
                        readonly 
                        :value="thisFullUrl"/>
                      <button @click="copy" class="btn btn-red" data-bs-toggle="popover" title="Salin link">
                        <i class='bx bx-copy'></i>
                      </button>
                    </div>
                  </div>

                  <form @submit.prevent="addCart" class="mt-3">
                    <div class="detail-info-total">
                      <div class="form-group">
                        <label class="form-label w-100" for="">Jumlah</label>
                        <input type="number" class="input" required min="1" v-model="qty">
                        <span class="detail-info-stock">Stok {{productDetail.stock}} unit</span>
                      </div>
                    </div>
                    <div class="detail-info-button">
                      <button v-if="user?.id" type="submit" class="btn btn-red btn-block">
                        <i class='bx bx-cart-add'></i> Masukkan keranjang
                      </button>
                      <router-link to="/login" v-else class="btn btn-red btn-block">
                        <i class='bx bx-cart-add'></i> Masukkan keranjang
                      </router-link>
                      <a v-if="user" class="btn btn-red btn-block" @click="setModal(true, 'negosiasi')">
                        <i class='bx bxs-info-circle'></i> Info negosiasi harga
                      </a>
                      <router-link to="/login" v-else-if="!user" class="btn btn-red btn-block">
                        <i class='bx bxs-info-circle'></i> Info negosiasi harga
                      </router-link >
                    </div>
                  </form>
              </div>
            </div>

            <div class="detail-middle-container">
              <ul class="accordion-custom">
                <li>
                  <a class="accordion accordion-custom-btn active" @click="openAccordion($event)">
                    <h5>Spesifikasi Produk</h5>
                    <span class="icon"><i class='bx bx-chevron-down'></i></span>
                  </a>
                  <div class="accordion-custom-content">
                    <ul v-if="spesifikasi" class="spesifikasi-produk" >
                      <li v-for="(spes, index) in productDetail.spesifikasi" :key="index">
                        {{spes.name}} : {{spes.value != null ? spes.value : ''}}
                      </li>
                    </ul>
                    <i v-else class="text-center">Belum ada data spesifikasi</i>

                  </div>
                </li>
                <li>
                  <a class="accordion accordion-custom-btn active" @click="openAccordion($event)">
                    <h5>Deskripsi Produk</h5>
                    <span class="icon"><i class='bx bx-chevron-down'></i></span>
                  </a>
                  <div class="accordion-custom-content " v-if="productDetail.descriptions " v-html="productDetail.descriptions"></div>
                  
                  <div class="accordion-custom-content " v-else>
                    <i  class="text-center">Belum ada data deskripsi</i>
                  </div>

                </li>
              </ul>
            </div>
            
            <div class="detail-bottom-container">
              <div class="detail-bottom-ulasan">
                <div class="detail-ulasan-left">
                  <div class="ulasan-left-container">
                    <p><b>Ulasan</b></p>
                    <div class="detail-bottom-rate">
                      <h3>{{parseFloat(productDetail.star_review).toFixed(1)}} </h3> <p> / 5.0</p>
                    </div>
                    <div class="detail-bottom-star">
                      <!-- <span>{{productDetail.star_review}}</span> -->
                      <span v-if="productDetail.star_review == 1">
                        <i class='bx bxs-star' ></i>
                      </span>
                      <span v-if="productDetail.star_review == 2">
                        <i class='bx bxs-star' v-for="str2 in 2" :key="str2"></i>
                      </span>
                      <span v-if="productDetail.star_review == 3">
                        <i class='bx bxs-star' v-for="str3 in 3" :key="str3"></i>
                      </span>
                      <span v-if="productDetail.star_review == 4">
                        <i class='bx bxs-star' v-for="str4 in 4" :key="str4"></i>
                      </span>
                      <span v-if="productDetail.star_review == 5">
                        <i class='bx bxs-star' v-for="str5 in 5" :key="str5"></i>
                      </span>
                      <span v-if="productDetail.star_review > 1 && productDetail.star_review < 2">
                        <i class='bx bxs-star' v-for="strh1 in 1" :key="strh1"></i>
                        <i class="bx bxs-star-half"></i>
                      </span>
                      <span v-if="productDetail.star_review > 2 && productDetail.star_review < 3">
                        <i class='bx bxs-star' v-for="strh2 in 2" :key="strh2"></i>
                        <i class="bx bxs-star-half"></i>
                      </span>
                      <span v-if="productDetail.star_review > 3 && productDetail.star_review < 4">
                        <i class='bx bxs-star' v-for="strh3 in 3" :key="strh3"></i>
                        <i class="bx bxs-star-half"></i>
                      </span>
                      <span v-if="productDetail.star_review > 4 && productDetail.star_review < 5">
                        <i class='bx bxs-star' v-for="strh4 in 4" :key="strh4"></i>
                        <i class="bx bxs-star-half"></i>
                      </span>
                    </div>
                    <p>({{parseInt(productDetail.count_review)}} Ulasan)</p>
                  </div>
                </div>
                <div class="detail-ulasan-right">
                  <!-- <ul class="nav nav-tabs reviews card-header-tabs" data-bs-tabs="tabs">
                    <li class="nav-item">
                        <a class="nav-link active" aria-current="true" data-bs-toggle="tab" href="#semua">Semua</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" data-bs-toggle="tab" href="#five">
                          <i v-for="star in 5" :key="star" class='bx bxs-star' ></i>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" data-bs-toggle="tab" href="#four">
                          <i v-for="star4 in 4" :key="star4" class='bx bxs-star' ></i>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" data-bs-toggle="tab" href="#three">
                          <i v-for="star3 in 3" :key="star3" class='bx bxs-star' ></i>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" data-bs-toggle="tab" href="#two">
                          <i v-for="star2 in 2" :key="star2" class='bx bxs-star' ></i>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" data-bs-toggle="tab" href="#one">
                          <i class='bx bxs-star' ></i>
                        </a>
                    </li>
                  </ul> -->
                  <ul class="ulasan-rate-list">
                    <li class="ulasan-rate-item active" @click="showComment($event)">Semua</li>
                    <li v-for="index in 5" :key="index"  class="ulasan-rate-item" @click="showComment($event, index)">
                      <!-- <i v-for="star in (6 - index)" :key="star" class='bx bxs-star star' @click="starClicked()"></i> -->
                      <template v-if="(6-index) === 5">
                        ★★★★★
                      </template>
                      <template v-if="(6-index) === 4">
                        ★★★★
                      </template>
                      <template v-if="(6-index) === 3">
                        ★★★
                      </template>
                      <template v-if="(6-index) === 2">
                        ★★
                      </template>
                      <template v-if="(6-index) === 1">
                        ★
                      </template>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="card-body tab-content">
                <div class="tab-pane active" id="semua">
                    <ul class="comment-list">
                    <template v-for="list in filteredData" :key="list.id">
                      <li class="comment-item" id="ulasan">
                      <!-- <li class="comment-item" v-for="list in item.reviews" :key="list.id"> -->
                        <!-- <template v-if="list.star === 5"> -->
                        <ulasan-list
                        :id="list.id"
                        :customerImg="list.customer.image"
                        :name="list.customer.name"
                        :time="dateTime(list.updated_at)"
                        :star="list.star"
                        :notes="list.notes"
                        :images="list.image"
                        :responNotes="list.respon_notes"
                        >
                        </ulasan-list>
                        <!-- </template> -->
                        <!-- <pagination v-if="list.length > 0"></pagination> -->
                      </li>
                    </template>

                    
                    <p class="nodata" v-if="filteredData.length == 0">Tidak ada ulasan.</p>

                    <p class="my-3">
                      Data ke <b>{{curentpage + 1}} - {{jmlh_value >= searchData.length ? searchData.length : jmlh_value}}</b> dari <b>{{searchData.length}}</b> data.
                    </p>

                    <!-- <div style="display-flex">
                      <button class="btn btn-red p-1 px-2 rounded-0" @click="btnpage(-1)">
                        <i class="bx bx-chevron-left"></i>
                      </button>

                      <button v-if="(curentpage+1) ==initPagination && (curentpage-1) > 0" class="btn btn-outline-secondary p-1 px-2 rounded-0" @click="btnpage(-2)">
                        {{curentpage-1}} t1
                      </button>
                      <button v-if="curentpage >0" class="btn btn-outline-secondary p-1 px-2 rounded-0" @click="btnpage(-1)">
                        {{curentpage}} t2
                      </button>
                      <button 
                        class="btn btn-outline-secondary p-1 px-3 rounded-0"
                      >
                        {{ curentpage+1 }} t3
                      </button>
                      <button v-if="initPagination>1 && (curentpage+1) < initPagination" class="btn btn-outline-secondary p-1 px-2 rounded-0" @click="btnpage(+1)">
                        {{curentpage+2}} t4
                      </button>

                      <button class="btn btn-red p-1 px-2 rounded-0" @click="btnpage(+1)">
                        <i class="bx bx-chevron-right"></i>
                      </button>
                    </div> -->

                    <!-- <p class="my-3">
                      Data ke <b>{{begin + 1}} - {{end >= ulasanAll.length ? ulasanAll.length : end}}</b> dari <b>{{filterReviews.length}}</b> data.
                    </p>

                    <button class="btn btn-outline-secondary me-2" @click="pageNav('left')" :disabled="begin === 0">
                      <i class="bx bx-chevron-left"></i>
                    </button>
                    <button class="btn btn-outline-secondary ms-2" @click="pageNav('right')" :disabled="end >= filterReviews.length">
                      <i class="bx bx-chevron-right"></i>
                    </button> -->

                    <nav aria-label="Page navigation example">
                      <ul class="pagination">
                        <li class="page-item" @click="btnpage(-1)">
                          <a class="page-link" aria-label="Previous">
                            <span aria-hidden="true">&laquo;</span>
                          </a>
                        </li>
                        <li class="page-item"
                          v-if="(curentpage+1) ==initPagination && (curentpage-1) > 0"
                          @click="btnpage(-2)"
                        >
                          <a class="page-link">{{curentpage-1}}</a>
                        </li>
                        <li class="page-item"
                          v-if="curentpage >0"
                          @click="btnpage(-1)"
                        >
                          <a class="page-link">{{curentpage}}</a>
                        </li>
                        <li class="page-item"
                        :class="{'active': curentpage+1}"
                        >
                          <a class="page-link">{{curentpage+1}}</a>
                        </li>
                        <li class="page-item"
                          v-if="initPagination>1 && (curentpage+1) < initPagination"
                          @click="btnpage(+1)"
                        >
                          <a class="page-link">{{curentpage+2}}</a>
                        </li>
                        <li class="page-item" @click="btnpage(+1)">
                          <a class="page-link" aria-label="Next">
                            <span aria-hidden="true">&raquo;</span>
                          </a>
                        </li>
                      </ul>
                    </nav>
                    </ul>
                </div>
               </div>
              
            </div>
          </div>

          <div v-else-if="!loadingProduct && Object.keys(productDetail).length == 0" class="detail-content-container">
            <div class="product-content-null text-center">
              <div>
                <img :src="require(`../assets/img/empty-illustration.svg`)" alt="">
                <b class="d-block">Produk tidak ditemukan ..</b>
              </div>
            </div>
          </div>

        </div>

        

        <Product v-if="productsRelated.length > 0">
          <div class="product-header mb-0">
            <h3 class="title">Produk Terkait</h3>
            <router-link :to="{ path: '/produk?page=1', query: { kategori: idKategori }}" class="product-link custom-link">
              Lihat semua <i class='bx bx-right-arrow-alt' ></i>
            </router-link>
          </div>
            
          <div class="product-content" v-if="productsRelated">
            <product-list 
              v-for="(related, index) in productsRelated" 
              :key="related" 
              :index="index"
              :id="related.id"
              :img="related.image"
              :title="related.name"
              :desc="related.descriptions"
              :price="related.price"
              :promo="related.promo"
              :rating="related.star_review"
              :status="related.status"
              @click="reload()"
              >
              </product-list>
          </div>
          <div v-else class="product-content-null">
            <img :src="require(`../assets/img/empty-illustration.svg`)" alt="">
          </div>
        </Product>
        
        <Modal
          :show="modal.showModal"
          :header="modal.header"
          :headerBorder="modal.headerBorder"
          :title="modal.title"
          :titleText="modal.titleText"
          :footer="modal.footer"
          :footerBtnPosition="modal.footerBtnPosition"
          :footerBorder="modal.footerBorder"
          :btnCancel="modal.btnCancel"
          :btnSubmit="modal.btnSubmit"
          :btnSubmitText="modal.btnSubmitText"
          :btnCancelText="modal.btnCancelText"
          :btnSubmitWidth="modal.btnSubmitWidth"
          :btnCancelWidth="modal.btnCancelWidth"
          @closeModal="closeModal"
          @submitModal="submitModal"
        >
          <!-- Notif add cart -->
          <div v-if="modalTipe === 'keranjang'">
            <div class="text-center mt-2 border-2 border-light border-bottom">
              <i class='bx bxs-check-circle ' style="font-size:115px; color:var(--primary-color)"></i>
              <div class="notif-text">
                <h5>Produk berhasil ditambahkan</h5>
              </div>
            </div>
            <div class="text-center mt-2">
              <router-link :to="'/keranjang-belanja'" @click="closeModal()" class="btn btn-red"><i class='bx bx-cart'></i> Lihat keranjang belanja</router-link>
            </div>
          </div>

          <!-- Info Negosiasi -->
          <div v-else-if="modalTipe === 'negosiasi'">
            <div class="negosisasi-container">
              <div :class="{ 'loading-skeleton' : loading }" v-if="loading">
                  <p>loading..</p>
                  <p>loading..</p>
                  <p>loading..</p>
              </div>
              <p v-html="settings.tac_price_nego">
              </p>
            </div>
          </div>

          <div v-else-if="modalTipe === 'modalErrorCheckout'" class="d-flex align-items-center justify-content-center text-center mt-2">
            <div>
              <i class='bx bxs-error-circle' style="font-size:115px; color: #E40C0C;"></i>
              <h5>{{this.checkoutErrorMessage}}</h5>
            </div>
          </div>
        </Modal>


        <!-- Preview Image -->
        <div v-if="displayPreview" id="previewImage" @click.self="closePreview()">
          <swiper
              :slidesPerView="1"
              :spaceBetween="30"
              :loop="true"
              :pagination="{
              clickable: true,
              }"
              :navigation="true"
              :modules="modules"
              class="swiperPreviewImage"
          >
              <swiper-slide v-for="(preview, index) in imagesPreview" :key="index">
                <img :src="baseUrl+''+preview.image" class="image-preview-item">
              </swiper-slide>
          </swiper>
        </div>

        <div v-if="loadingSubmit" class="loading-submit-animation-container" >
            <div class="loading-submit-animation-content ">
                <div>
                    <div class="lds-facebook"><div></div><div></div><div></div></div>
                    <p>Mohon tunggu, data sedang diproses</p>
                </div>
            </div>
        </div>

      </div>
</template>

<script>
import axios from 'axios'
import Product from '../components/Product.vue'
import ProductList from '../components/ProductList.vue'
import UlasanList from '../components/Ulasan/UlasanList.vue'
// import Pagination from '../components/Pagination.vue'
import Modal from '../components/Modal.vue'
import moment from 'moment'

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import "swiper/css";

import "swiper/css/scrollbar";
import "swiper/css/navigation";

import { Keyboard, Scrollbar, Navigation } from "swiper";
// import { debuggerStatement } from '@babel/types'

export default {
  name: 'product-detail',
  props:['dataCategories','dataSubCategories','dataAllProducts', 'user','supportWebp','dataCarts'],
  setup() {
      return {
        modules: [Keyboard, Scrollbar, Navigation],
      };
  },
  data(){
    return{
      qty:'',
      search: '',
      product_id: '',
      ulasan:[],
      ulasanAll:[],
      ulasanList:[],
      ulasanDetail:[],
      productDetail:{},
      imagesPreview:[],
      imageProductDetail:[],
      modalTipe: '',
      baseUrl: process.env.VUE_APP_NAME_AXIOS,
      productImage: '',
      thumbActive:0,
      idKategori:'',
      idSubKategori:'',
      productsRelated:[],
      jumlahPromo:0,
      displayPreview : false,
      spesifikasi: false,
      loadingSubmit : false,
      loadingProduct: true,
      thisFullUrl:window.location.href,
      checkoutErrorMessage:'',
      settings: [],
      loading: false,
      filterStar: {},
      modal:{
        showModal: false,
        header:false,
        headerBorder: false,
        title: false,
        titleText: '',
        footer: false,
        footerBtnPosition:'',
        footerBorder: false,
        btnCancel: false,
        btnSubmit: false,
        btnSubmitText: '',
        btnCancelText: '',
        btnSubmitWidth: '',
        btnCancelWidth: '',
      },
      curentpage: 0,
      jmlh_value: 3,
      datapage: 0,
      datalist: [],
      items: [
        { id: 1, first_name: 'Fred', last_name: 'Flintstone' },
        { id: 2, first_name: 'Wilma', last_name: 'Flintstone' },
        { id: 3, first_name: 'Barney', last_name: 'Rubble' },
        { id: 4, first_name: 'Betty', last_name: 'Rubble' },
        { id: 5, first_name: 'Pebbles', last_name: 'Flintstone' },
        { id: 6, first_name: 'Bamm Bamm', last_name: 'Rubble' },
        { id: 7, first_name: 'The Great', last_name: 'Gazzoo' },
        { id: 8, first_name: 'Rockhead', last_name: 'Slate' },
        { id: 9, first_name: 'Pearl', last_name: 'Slaghoople' },
        { id: 10, first_name: 'Oge', last_name: 'Jodie' },
        { id: 11, first_name: 'Rubble', last_name: 'Glaice' },
        { id: 12, first_name: 'Lezzo', last_name: 'Boy' },
        { id: 13, first_name: 'Prilly', last_name: 'Curly' },
        { id: 14, first_name: 'Bleyz', last_name: 'Polly' },
      ],
      list_paged: [],
      begin: 0,
      end: 3,
      current_page: 0
    }
  },
  components: {
    Product,
    ProductList,
    UlasanList,
    // Pagination,
    Swiper,
    SwiperSlide,
    Modal,
  },
  methods: {
    dateTime(value) {
			return moment(value).format("DD MMMM YYYY hh:mm:ss A");
		},
    copy() {
      this.$refs.clone.focus();
      document.execCommand('copy');
      const notif = document.querySelector(".notif-copy")
      notif.classList.contains('show') ? '' : notif.classList.add('show')
      setTimeout( ()=>{
        notif.classList.contains('show') ? notif.classList.remove('show') : '' 
      },
        1000
      )
    },

    pageNav(direction){
      if (direction === 'left') {
        this.begin -= 3
        this.end -= 3
      } else if(direction === 'right') {
        this.begin += 3
        this.end += 3
      } else if(!isNaN(direction)){
        this.end = direction * 3
        this.begin = this.end - 10
      }
      if (this.search === '') {
        this.list_paged = this.ulasanAll.slice(this.begin, this.end)
      } else{
        this.list_paged = this.filterReviews.slice(this.begin, this.end)
      }
      this.current_page = this.end / 3
    },

    async entries_data(value) {
      this.curentpage = value - 1;
    },
    btnpage(value) {
        let datapage = this.initPagination
            
       if(this.curentpage <= 0 && value == -1){
            this.curentpage = 0
        } else if (this.curentpage <= 0 && value == -2) {
            this.curentpage = 0
        } else if (this.curentpage >= 0 && this.curentpage < (datapage - 1)) {
            this.curentpage += value  
        } else if (this.curentpage >= 0 && this.curentpage <= datapage - 1 && value == -1) {
            this.curentpage += value  
        } else if (this.curentpage >= 0 && this.curentpage <= datapage - 1 && value == -2) {
            this.curentpage += value  
        }  

    },
    firstData(data) {
      this.datalist = [];
      for (let i = 0; i < this.jmlh_value; i++) {
        if (data.length == i) {
          break;
        }
        let dataval = data[i];
        this.datalist.push(dataval);
      }

    },
    btn_paginate() {
    },

    // setPaginate(i) {
    //   if (this.current == 1) {
    //     return i < this.paginate;
    //   }
    //   else {
    //     return (i >= (this.paginate * (this.current - 1)) && i < (this.current * this.paginate));
    //   }
    // },
    // setStatus(status) {
    //   this.status_filter = status;
    //   this.$nextTick(function () {
    //     this.updatePaginate();
    //   });
    // },
    // updateCurrent(i) {
    //   this.current = i;
    // },
    // updatePaginate() {
    //   this.current = 1;
    //   this.paginate_total = Math.ceil(this.ulasanAll.length/this.paginate);
    // },

    getSetting(){
      this.loading = true
      axios.get('setting')
        .then((result) => {
            // console.log('setting : ', result.data.data)
            this.settings = result.data.data
            this.loading = false
        }).catch(() => {
            // console.log(err.response);
            this.loading = false
        })
    },
    showPreview(image){
      if(this.imageProductDetail.length >  0){
        this.displayPreview = true
        image = image.replace(this.baseUrl, '')
        let indexTrue = 0
        let imageTrue = false
        let arrAwalImage = []
        for(let i = 0; i < this.imageProductDetail.length; i++){
          if(this.imageProductDetail[i].image == image){
            indexTrue = i
            imageTrue = true
            this.imagesPreview[0] = this.imageProductDetail[i]
          }else if(this.imageProductDetail[i].image != image && i > indexTrue && imageTrue){
            this.imagesPreview[i - indexTrue] = this.imageProductDetail[i]
          }else if(this.imageProductDetail[i].image != image && i > indexTrue && !imageTrue){
            if(! arrAwalImage.includes(this.imageProductDetail[i])){
              arrAwalImage.push(this.imageProductDetail[i])
            }
          }else if(this.imageProductDetail[i].image != image){
            if(! arrAwalImage.includes(this.imageProductDetail[i])){
              arrAwalImage.push(this.imageProductDetail[i])
            }
          }
          
          if(i == this.imageProductDetail.length - 1){
            for(let j = 0; j < arrAwalImage.length; j++){
              if(! this.imagesPreview.includes(arrAwalImage[j])){
                this.imagesPreview.push(arrAwalImage[j])
              }
            }
          }
        }

        if(!document.body.classList.contains('no-scroll')){
          document.body.classList.add('no-scroll')
        }

      }
    },
    closePreview(){
      this.displayPreview = false
      if(document.body.classList.contains('no-scroll')){
          document.body.classList.remove('no-scroll')
      }
    },
    tipeModal(tipe){
      this.modalTipe = tipe
    },
    openAccordion(event){
      if(event.target.classList.contains("active")){
        event.target.classList.remove("active")
      }else{
        event.target.classList.add("active")
      }
    },
    showComment(event, index){
      const ulasan = document.querySelectorAll('.ulasan-rate-item')
      ulasan.forEach(n => {
        n.classList.remove('active')
      });

      if(event){
        // console.log('reviews index: ',6-index);
        if ((6-index) === 5) {
          this.search = '5'
          this.filterPages
        } else if((6-index) === 4){
          this.search = '4'
          this.filterPages
        } else if((6-index) === 3){
          this.search = '3'
          this.filterPages
        } else if((6-index) === 2){
          this.search = '2'
          this.filterPages
        } else if((6-index) === 1){
          this.search = '1'
          this.filterPages
        } else{
          this.search = ''
        }
      }

      event.target.classList.add("active")
    },
    
    getUlasanAll(){
      // console.log('Ulasan bintang 5 Clicked!');
      axios.get('/product/'+this.$router.currentRoute._value.query.id_produk)
      .then(res=>{
        this.productDetail = res.data.data
        
        this.ulasanDetail = this.productDetail.transaction_detail
        // console.log('Ulasan Detail result', this.ulasanDetail);
        // console.log('Ulasan All', this.ulasanDetail);

        this.ulasanDetail.forEach(ulasan => {
          if(ulasan.reviews !== null){
            // console.log('Ulasan All', ulasan.reviews);
            // if(ulasan.reviews[0].star === this.$router.currentRoute._value.query.rating_star){
               return this.ulasanAll.push(ulasan.reviews)
            // }
          }
          // console.log('Ulasan All', this.ulasanAll);
        });
        this.pageNav()
      }).catch(() => {
        // console.log(err.response);
      })
    },
    
    getDetailProduct(id){
      this.product_id = id
      axios.get('/product/'+id)
      .then(res=>{
        
        this.productDetail = res.data.data
        if(Object.values(this.productDetail).length > 0){
          this.spesifikasi = this.productDetail.spesifikasi.length > 0 ? true : false
          this.jumlahPromo = this.productDetail.promo.length
          if(res.data.data.subcategory != null){

            // get related product
            this.idKategori = res.data.data.subcategory.category_id
            axios.get('/product?category='+res.data.data.subcategory.category_id)
            .then(response => {
              if(Object.values(response.data.data).length > 0){
                this.productsRelated = response.data.data
                this.productsRelated = Object.values(this.productsRelated).slice(0, 6)
              }
            })
            .catch(error =>{
              console.log(error)
            })
          }
          if(res.data.data.image.length > 0){
            if(this.supportWebp && res.data.data.image[0].webp !== null){
              this.setProductImage(this.baseUrl+''+res.data.data.image[0].webp, 0)
            }else{
              this.setProductImage(this.baseUrl+''+res.data.data.image[0].image, 0)
            }              

            this.imageProductDetail = res.data.data.image
          }else{
            this.setProductImage('', 0)
          }

          setTimeout(() => {
            this.loadingProduct = false
          },1000)

          // this.ulasanDetail = this.productDetail.transaction_detail
          
          // let reviews = this.productDetail.transaction_detail
          // for (let i = 0; i < reviews.length; i++) {
          //   if (reviews[i].reviews.length !== 0) {
          //     console.log('Ulasan Detail', reviews[i].reviews);
          //   }
          // }
          
        }
      }).catch(()=>{
        this.loadingProduct = false
        // console.log(err)
      })
    },
    setProductImage(src, index){
      this.thumbActive = index
      this.productImage = String(src)
    },
    addCart(){
      let productID = this.product_id
      let qtyProductCart = []
      if(Object.keys(this.dataCarts).length > 0){
        qtyProductCart = this.dataCarts.filter(function(data){
          return Number(data.product_id) == Number(productID)
        })
        if(qtyProductCart.length > 0){
          if((Number(qtyProductCart[0].qty) + Number(this.qty)) > Number(this.productDetail.stock) ){
            this.qty =  ''
            this.setModal(true,'modalErrorCheckout')
            this.checkoutErrorMessage = 'Kamu telah memasukan ('+qtyProductCart[0].qty+') barang ke dalam keranjang, kamu tidak bisa menambah jumlah barang di keranjang karena telah melebihi batas pembelian'
          }else{
            this.prosesAddCart()
          }
        }else{
          this.prosesAddCart()
        }
      }else{
        this.prosesAddCart()
      }
      
    },
    prosesAddCart(){
      this.loadingSubmit = true
      let data = new FormData()
      data.append('customer_id', this.user?.id)
      data.append('product_id', this.product_id)
      data.append('qty', this.qty)

      axios.post('transaction/cart', data)
      .then(() => {
        this.loadingSubmit = false
        this.$store.dispatch('carts')
        this.qty =  ''

        //show modal sukses
        this.setModal(true, 'keranjang')

        // console.log(response)
      }).catch(err => {
        this.qty =  ''
        this.loadingSubmit = false
        this.setModal(true,'modalErrorCheckout')
        // if (this.user?.id) {
        //   this.setModal(true,'modalErrorCheckout')
        // } else{
        //   this.$router.push('/login')
        // }
        this.checkoutErrorMessage = err.response.data.message
      })
    },
    setModal(condition, tipe){
      this.resetDataModal()
      this.modal.showModal= condition
      condition == true ? this.modalTipe = tipe : ''
      if(tipe == 'keranjang' && condition){
        this.modal.header=true
      }else if(tipe == 'modalErrorCheckout' && condition){
        this.modal.header=true
        this.modal.footer= true
        this.modal.footerBtnPosition='right'
        this.modal.btnSubmit= true
        this.modal.btnSubmitText= 'Oke'
        this.modal.btnSubmitWidth='auto'
      }else if(tipe == 'negosiasi' && condition){
        this.modal.header=true
        this.modal.headerBorder=true
        this.modal.title=true
        this.modal.titleText='Informasi Negosiasi'
        this.modal.footer=true
        this.modal.footerBorder=true
        this.modal.footerBtnPosition='right'
        this.modal.btnSubmitText='Oke'
        this.modal.btnSubmitWidth='auto'
        this.modal.btnSubmit=true
      }
    },
    resetDataModal(){
      this.modal.showModal = false
      this.modal.header = false,
      this.modal.headerBorder = false
      this.modal.title = false
      this.modal.titleText = '' 
      this.modal.footer = false
      this.modal.footerBtnPosition =''
      this.modal.footerBorder = false
      this.modal.btnCancel = false
      this.modal.btnSubmit = false
      this.modal.btnSubmitText = ''
      this.modal.btnCancelText = ''
      this.modal.btnSubmitWidth = ''
      this.modal.btnCancelWidth = ''
    },
    closeModal(){
      this.setModal(false, this.modalTipe)
      this.$store.dispatch('carts')
    },
    submitModal(){
      this.setModal(false, this.modalTipe)
    },
    addView(){
      axios.post('/product/customer/view/'+this.product_id, '')
      .then(() => {
        // console.log(res)
      }).catch(() => {
        // console.log(err)
      })
    },
    reload(){
      this.loadingProduct = true
      setTimeout(() => {
        window.location.reload()
        this.loadingProduct = false
      }, 500)
    },
    getUser(){
      axios.get(`user`)
      .then(() => {
        // console.log('user: ', res);
          // this.user = res.data.data
      }).catch(() => {
        // console.log(err.response);
        // if (err.response.data.message == 'Unauthenticated.'){
        //   this.$cookies.remove('token')
        //   // this.$router.push('/login')
        // }
      })
    },
    autoLogout(){
      axios.interceptors.response.use( (response) => {
            // Return a successful response back to the calling service
            return response;
        }, (error) => {
            // console.log('response error', error.response)
            if (error.response.status == 401) {
              if (error.response.data.message == "Unauthenticated.") {
                  // Hapus token di cookie
                  this.$cookies.remove('token')
                  this.$store.dispatch('cart')
                  // Redirect Home atau login
                  this.$router.push('/login')
              }
          }

          return Promise.reject(error.response);
        })
    }
  },
  mounted(){
    if(this.$cookies.get('token')){
      this.getUser()
      this.addView()
    }
    this.autoLogout()
    this.getSetting()
    this.getUlasanAll()
    // this.pageNav
    // console.log('Ulasan', this.ulasanDetail)
  },
  computed: {
    filterReviews(){
      if (!this.search){
          return this.ulasanAll
      }

      return this.ulasanAll.filter(fund => {
          return fund.star.toString().toLowerCase().includes(this.search.toLowerCase())
      })
    },
    filterPages(){
      if (!this.search){
          return this.list_paged
      }

      return this.list_paged.filter(fund => {
          return fund.star.toString().toLowerCase().includes(this.search.toLowerCase())
      })
    },
    initPagination() {
      return Math.ceil(this.searchData.length / this.jmlh_value);
    },
    searchData: function () {
      return this.ulasanAll.filter((data) => {
        return data.star.toString().toLowerCase().includes(this.search.toLowerCase())
      });
    },
    filteredData: function () {
      let pageStart = this.curentpage * this.jmlh_value;
      let pageEnd = this.jmlh_value * (this.curentpage * 1 + 1);

      return this.searchData.filter((item, index) => {
        return index >= pageStart && index < pageEnd;
      });
    },
  },
  created(){
    this.product_id = this.$router.currentRoute._value.query.id_produk
    this.getDetailProduct(this.$router.currentRoute._value.query.id_produk)
    
    // this.showReviews5()
    this.qty = ''
    this.imagesPreview = []
  },
  watch:{
    '$route.query': {
      handler: function() {
        window.scroll({
          top: 100,
          left: 100,
          behavior: 'smooth'
        });
        if(this.$router.currentRoute._value.path == '/produk-detail'){
          this.getDetailProduct(this.$router.currentRoute._value.query.id_produk)
          this.qty = ''
          this.imagesPreview = []
          this.curentpage = 0
        }
      },
      deep: true,
      immediate: true,
    },
    allreviews(){
      this.list_paged
    },
    search(value) {
      this.isisearch = value;
      this.curentpage = 0
    },
  },
}
</script>

<style>
.detail-info-price {
  font-size: 40px;
  font-weight: bold;
  margin-top: -10px;
}
.detail-info-price-container {
  text-align:left;
  padding:0px;
  background-color: none;
}
.line-through {
  margin-top: -5px;
}
.input-copy {
  margin: 0px;
}
.accordion-custom-content {
  background-color: #FFF !important;
} 
#previewImage{
  position: fixed;
  top:0;
  bottom:0;
  left: 0;
  right: 0;
  background-color: rgba(0,0,0,.40);
  width: 100%;
  height: 100vh;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.swiperPreviewImage{
  width: 70vw;
  background: black;
  border:4px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
  transform:translateY(0%);
}
@media screen and (max-width: 576px){
  .swiperPreviewImage{
    width: 90vw;
  }
  .detail-info-title{
    font-size: 18pt;
  }
  .detail-info-price {
    font-size: 24px;
    font-weight: bold;
    margin-top: 0px;
  }
}


.swiperPreviewImage .swiper-slide{
  display: flex;
  justify-content: center;
  align-items: center;
  height:auto;
}
.image-preview-item{
  max-height: 70vh;
  height:auto;
  width:auto;
}
</style>

