<template>
    <router-link :to="{ path: '/produk-detail', query: { id_produk: id }}" class="product-list">
        <span v-if="promo.length > 0" class="product-discount">Off {{parseInt(promo[0].discount)}}%</span>
        <div class="product_card">
            <div v-if="getSrc() != ''" class="product-card-img" :style="'background-image:url('+baseUrl+`/`+getSrc()+')'">
            </div>
            
            <div v-else class="product-card-img d-flex align-items-center justify-content-center">
                <i class='bx bxs-image' style="color:#212121" ></i>
            </div>
            <div class="product-card-content">
                <p class="product-card-title mb-0">{{title}}</p>
                <div class="product-card-footer d-flex">
                    <div class="product-card-rating">
                        <i class='bx bxs-star' ></i>
                        <span>{{parseFloat(rating).toFixed(1)}}</span>
                    </div>
                    <div v-if="promo.length > 0" class="product-card-price w-100">
                        <p class="price-now m-0">Rp. {{ new Intl.NumberFormat('id-ID').format(parseInt(this.promo[0].price_promo).toFixed()) }}</p>
                        <p class="price-before">Rp. {{ new Intl.NumberFormat('id-ID').format(parseInt(this.price).toFixed())}}  </p>
                    </div>
                    <div v-else class="product-card-price w-100">
                        <p class="price-now m-0">Rp. {{ new Intl.NumberFormat('id-ID').format(parseInt(this.price).toFixed()) }}</p>
                    </div>
                </div>
            </div>
        </div>
    </router-link>
</template>

<script>

export default {
    props:['id','img','title','desc','price','rating','promo','status','supportWebp'],
    name: 'product-list-component',
    data(){
        return {
            baseUrl:process.env.VUE_APP_NAME_AXIOS,
            src:''
        }
    },
    methods: {
        getSrc(){
            if(this.img != undefined){
                if(!Array.isArray(this.img)){
                    return this.img
                }else{
                    if(this.img.length > 0){
                        let data = this.img.filter(function (e) {
                            return e.default_image === true
                        })
                        if(data.length > 0){
                            if(this.supportWebp && data[0].webp != null){
                                return data[0].webp 
                            }else{
                                return data[0]?.image 
                            }
                        }else{
                            if(this.supportWebp && data[0].webp != null){
                                return this.img[0]?.webp 
                            }else{
                                return this.img[0]?.image 
                            }
                        }
                    }
                }
            }else{
                return ''
            }
        }
    },
    created(){
        this.getSrc()
    }
}
</script>
<style scoped>
.product-card-title, .product-card-desc {
    font-size: 14px;
}
.product-card-title {
    font-weight: normal !important;
}
.product-card-price {
    font-weight: bold !important;
    font-size: large;
}
</style>

