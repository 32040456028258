<template>
      
      <div class="keranjang section section-modif">

        <div class="keranjang-container container">

          <div class="d-flex justify-content-between">
            <h3 class="title">Keranjang Belanja</h3>
            <nav aria-label="breadcrumb" class="">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><router-link to="/" class="custom-link">Beranda</router-link></li>
                <li class="breadcrumb-item active" aria-current="page">Keranjang Belanja</li>
              </ol>
            </nav>
          </div>

          <div class="keranjang-content-container">
            <div class="product-content d-flex justify-content-center align-items-center" v-if="loading" >
                <div class="lds-facebook"><div></div><div></div><div></div></div>
            </div>

            <div class="bg-white p-3 mb-4" v-if="!loading && dataCarts.length > 0">
              <div class="keranjang-table-container">
                <table>
                  <tr >
                    <th></th>
                    <th style="min-width:200px;">Nama Produk</th>
                    <th>Jumlah</th>
                    <th>Harga </th>
                    <th>Sub Total</th>
                  </tr>
                  <tbody v-for="(cart,index) in dataCarts.slice().reverse()" :key="index">
                    <tr>
                      <td>
                        <input type="checkbox" v-model="cart_id" :value="cart.id" 
                        @change="sumPrice(cart.id, cart.product.promo.length > 0 ? cart.product.promo[0].price_promo : cart.product.price, $event.target.checked, cart.qty,'add')"
                        :disabled="cart.product.stock < cart.qty || cart.product.status == 'disabled' ? true : false"
                        >
                      </td>
                      <td style="min-width:200px;" >
                        <div class="d-flex">
                          <!-- Image Cart -->
                          <div v-if=" supportWebp">
                              <div :style="[cart.product.image.webp === null ? {'background-image':'url('+baseUrl+`/`+cart.product.image.image+')'} : {'background-image':'url('+baseUrl+`/`+cart.product.image.webp+')'}]" class="keranjang-produk-img"></div>
                          </div>
                          <div v-else-if=" !supportWebp">
                              <div :style="'background-image:url('+baseUrl+`/`+cart.product.image.image+')'" class="keranjang-produk-img"></div>
                          </div>
                          <div v-else>
                            <div class="keranjang-produk-img  d-flex align-items-center justify-content-center">
                                <i class='bx bxs-image' style="color:#212121" ></i>
                            </div>
                          </div>
                          <div>
                            <p>{{cart.product.name}}</p>
                          </div>
                        </div>
                        <div class="stok-cart">
                          <div>Stok : {{cart.product.stock}}</div>
                          <div v-if="cart.product.stock < cart.qty" class="badge-small-custom">Stok kurang</div>
                          <div v-if="cart.product.status == 'disabled'" class="badge-small-custom">Produk dinonaktifkan</div>
                        </div> 

                      </td>
                      <!-- @keyup="sumPrice(cart.id, cart.product.promo.length > 0 ? cart.product.promo[0].price_promo : cart.product.price,
                              $event.target.checked, cart.qty,'update')" -->
                      <td class="text-center">
                        <div class="d-flex justify-content-center w-100">
                          <div class="input-group" style="flex-wrap:nowrap;">
                              <input type="number" min="1" class="input" style="width:90px;" 
                              v-model="cart.qty" :key="index" 
                              onkeypress="return event.charCode >= 48"
                              @change="sumPrice(cart.id, cart.product.promo.length > 0 ? cart.product.promo[0].price_promo : cart.product.price,
                              $event.target.checked, cart.qty,'update')"
                              
                              required>
                            <button type="button" class="btn bg-white border" disabled>{{cart.product.unit.name ? cart.product.unit.name : '-'}}</button>
                          </div>
                        </div>
                      </td>
                      <td class="text-right" v-if="cart.product.promo.length > 0">
                        <span class="badge-custom-red mb-2" >{{Number(cart.product.promo[0].discount)}}%</span>
                        <p class="m-0">Rp. {{ new Intl.NumberFormat('id-ID').format(Number(cart.product.promo[0].price_promo).toFixed()) }}</p>
                        <p class="line-through m-0 font-small">Rp. {{ new Intl.NumberFormat('id-ID').format(Number(cart.product.price).toFixed())}}</p>
                      </td>
                      <td class="text-right" v-else>
                        <p class="m-0">Rp. {{ new Intl.NumberFormat('id-ID').format(Number(cart.product.price).toFixed())}}</p>
                      </td>
                      <td>
                        <b v-if="cart.product.promo.length > 0">Rp. {{ new Intl.NumberFormat('id-ID').format(Number(cart.product.promo[0].price_promo * cart.qty).toFixed())}}</b>
                        <b v-else>Rp. {{ new Intl.NumberFormat('id-ID').format(Number(cart.product.price * cart.qty).toFixed())}}</b>
                      </td>
                    </tr>
                    <tr v-if="cart.note != null">
                      <td></td>
                      <td colspan="4">
                        <div class="d-flex align-items-center mb-2">
                          <i class='bx bx-notepad'></i>
                          <h5 class="my-0 mx-2">Catatan</h5>
                        </div>
                        <p>{{cart.note}}</p>
                      </td>
                    </tr>
                    <tr>
                      <td></td>

                      <td colspan="4">
                        <div class="d-flex justify-content-end align-items-center" style="gap:.5rem;" >
                          <button class="btn btn-light d-flex align-items-center justfify-content-center" style="gap:.5rem;" @click="setModal(true, cart.id, 'modalFormCatatan')">
                            <i class='bx bx-pencil'></i><span>Tulis catatan</span>
                          </button>
                          <button class="btn btn-red" @click="setModal(true, cart.id, 'modalConfirmDelete')">
                            <i class='bx bx-trash-alt'></i>
                          </button>
                        </div>
                      </td>
                    </tr>

                  </tbody>
                </table>
              </div>
            </div>

            <div v-if="!loading && dataCarts.length == 0" class="product-content-null">
              <div>
                <img :src="require(`../assets/img/empty-illustration.svg`)" alt="">
                <p class="text-center"><b>Wah keranjang belanjamu masih kosong ..</b></p>
                <div class="d-flex justify-content-center">
                  <router-link to="/produk?page=1" class="text-center btn btn-red">Yuk belanja sekarang</router-link>
                </div>
              </div>
            </div>

            <h3 v-if="!loading" class="title">Ringkasan Belanja</h3>
            <div v-if="!loading" class="ringkasan-belanja-container">
              <h5>Total harga ({{totalBarang}} Barang) : </h5> 
              <h5 style="margin-left:1rem"> Rp. {{ new Intl.NumberFormat('id-ID').format(Number(totalPrice).toFixed())}}</h5>
            </div>

            <div class="d-flex justify-content-between py-3">
              <router-link to="/produk?page=1" class="btn btn-red-transparent ">
                <i class='bx bx-left-arrow-alt'></i>
                Lanjut belanja
              </router-link>
              <button @click="cartToCheckout" class="btn btn-red"><i class='bx bxs-basket'></i> Checkout</button>
            </div>
          </div>

        </div>

        <Product v-if="productTerlaris.length > 0">
          <div class="product-header">
            <h3 class="title">Rekomendasi untuk Anda</h3>
            <router-link to="/produk?page=1" class="product-link custom-link">
              Lihat semua <i class='bx bx-right-arrow-alt' ></i>
            </router-link>
          </div>
          <div class="product-content" >
            <product-list 
              v-for="(terlaris, index) in productTerlaris" 
              :key="terlaris" 
              :index="index"
              :id="terlaris.id"
              :img="terlaris.image"
              :title="terlaris.name"
              :desc="terlaris.descriptions"
              :price="terlaris.price"
              :promo="terlaris.promo"
              :rating="terlaris.star_review"
              :status="terlaris.status"
              >
              </product-list>
          </div>
        </Product>

        <Modal
          :show="modal.showModal"
          :header="modal.header"
          :headerBorder="modal.headerBorder"
          :title="modal.title"
          :titleText="modal.titleText"
          :footer="modal.footer"
          :footerBtnPosition="modal.footerBtnPosition"
          :footerBorder="modal.footerBorder"
          :btnCancel="modal.btnCancel"
          :btnSubmit="modal.btnSubmit"
          :btnSubmitText="modal.btnSubmitText"
          :btnCancelText="modal.btnCancelText"
          :btnSubmitWidth="modal.btnSubmitWidth"
          :btnCancelWidth="modal.btnCancelWidth"
          @closeModal="closeModal"
          @submitModal="submitModal"
        >
          <div v-if="modalTipe === 'modalConfirmDelete'" class="d-flex align-items-center justify-content-center text-center">
            <div class="my-2">
              <i class='bx bxs-error-circle' style="font-size:115px; color: #E40C0C;"></i>
              <h5>Anda yakin ingin menghapus produk ini?</h5>
            </div>
          </div>

          <div v-else-if="modalTipe === 'notifDelete'" class="d-flex align-items-center justify-content-center text-center">
            <div class="my-2">
              <i class='bx bxs-check-circle' style="font-size:115px; color: #E40C0C;"></i>
              <h5>Produk berhasil dihapus</h5>
            </div>
          </div>

          <div v-else-if="modalTipe === 'modalErrorCheckout'" class="d-flex align-items-center justify-content-center text-center">
            <div>
              <i class='bx bxs-error-circle' style="font-size:115px; color: #E40C0C;"></i>
              <h5>{{this.checkoutErrorMessage}}</h5>
            </div>
          </div>

          <div v-else-if="modalTipe === 'modalFormCatatan'">
            <textarea class="input w-100 my-2" v-model="catatan" id="inputCatatan" style="min-height:200px;" placeholder="Tulis catatan untuk produk ini .." required></textarea>
          </div>

        </Modal>

        <div v-if="loadingSubmit" class="loading-submit-animation-container" >
            <div class="loading-submit-animation-content">
                <div>
                    <div class="lds-facebook"><div></div><div></div><div></div></div>
                    <p>Mohon tunggu, data sedang diproses</p>
                </div>
            </div>
        </div>
      </div>

</template>

<script>
import axios from 'axios'

import Product from '../components/Product.vue'
import ProductList from '../components/ProductList.vue'
import Modal from '../components/Modal.vue'

export default {
  name: 'keranjang-belanja',
  props:['dataAllProducts','dataCarts','user','supportWebp'],
  data(){
    return{
      productTerlaris: [],
      modalTipe: '',
      baseUrl: process.env.VUE_APP_NAME_AXIOS,
      loading: true,
      idProduct: '',
      modal:{
        showModal: false,
        header:false,
        headerBorder: false,
        title: false,
        titleText: '',
        footer: false,
        footerBtnPosition:'',
        footerBorder: false,
        btnCancel: false,
        btnSubmit: false,
        btnSubmitText: '',
        btnCancelText: '',
        btnSubmitWidth: '',
        btnCancelWidth: '',
      },
      cart_id:[],
      catatan:'',
      totalBarang:0,
      totalPrice:0,
      dataCartsSelected:[],
      refreshCart: false,
      cart:{
        qty: 1
      },
      validation: false,
      loadingSubmit: false,
      checkoutErrorMessage: '',
      productChecked: false,
      // errorQty: ''
    }
  },
  components: {
    Product,
    ProductList,
    Modal
  },
  methods: {
    sumPrice(id, price, target, qty, action){
      // action == 'add' ? this.productChecked = target : this.productChecked = ''
      if(qty != 0 && qty != '' && qty != null){
        for (let i = 0; i < qty.length; i++) {
          if(qty[0] == '-'){
            qty[0] = ''
          }
        }
        let cartDipilih = {
            id:id,
            price:price,
            qty:qty
        };
        if(action == 'add' ){
          // untuk set data total harga semuanya
          if(target){
            this.dataCartsSelected.push(cartDipilih)
            this.totalBarang = 0
            this.totalPrice = 0
            this.dataCartsSelected.forEach(data => {
              this.totalBarang = this.totalBarang + data.qty
              this.totalPrice = this.totalPrice + Number(data.qty * data.price)
            })
          }else{
            let removeSelected = this.dataCartsSelected.filter(function(value){ 
                return value.id != id;
            });
            this.dataCartsSelected = removeSelected
            this.totalBarang = 0
            this.totalPrice = 0
            if(this.dataCartsSelected.length >  0){
              this.dataCartsSelected.forEach(data => {
                this.totalBarang = this.totalBarang + data.qty
                this.totalPrice = this.totalPrice + Number(data.qty * data.price)
              })
            }else{
              this.totalBarang = 0
              this.totalPrice = 0
            }
          }
        }else if(action == 'update'){
          // ini untuk mengupdate data ke database
          this.updateDataCart(id, 'qty', Number(qty))
          
          
          // apakah produk yang diupdate sedang dicentang
          let cekProduk = this.dataCartsSelected.filter(function(value){ 
              return value.id == id;
          });
          // jika iya update datanya
          if(cekProduk.length > 0){
            // ketika data di input qty di rubah
            let removeSelected = this.dataCartsSelected.filter(function(value){ 
                return value.id != id;
            });
  
            this.dataCartsSelected = removeSelected
            this.dataCartsSelected.push(cartDipilih)
            this.totalBarang = 0
            this.totalPrice = 0
            for(let i = 0; i < this.dataCartsSelected.length; i++){
              this.totalBarang = this.totalBarang + this.dataCartsSelected[i].qty
              this.totalPrice = this.totalPrice + Number(this.dataCartsSelected[i].qty * this.dataCartsSelected[i].price)
            }
          }
        }
      }else if(qty < 1){
        if (qty == '') {
          qty = 1
          this.updateDataCart(id, 'qty', Number(qty = 1))
        } else {
          alert('QTY tidak boleh negatif!')
        }
      }
      else{
        this.setModal(true, id, 'modalConfirmDelete')
        this.refreshCart = true
        
      }
      
    },
    cartToCheckout(){

      if(this.dataCartsSelected.length > 0){
        this.loadingSubmit =  true

        let data = new FormData();
        data.append('customer_id', this.user?.id)
  
        for(let i = 0; i < this.dataCartsSelected.length; i++){
          data.append('cart_id[]', this.dataCartsSelected[i].id)
          data.append('qty[]', this.dataCartsSelected[i].qty)
        }
        axios.post('transaction/checkout', data)
        .then(res => {

          this.$router.push('/checkout')
          this.loadingSubmit =  false
          this.$cookies.set('id_checkout', res.data.data.id);
          this.$cookies.set('user_id', this.user?.id);
          // this.$store.dispatch('carts')
        }).catch(err =>{
          this.loadingSubmit =  false
          this.checkoutErrorMessage = err.response.data.message
          this.setModal(true, '', 'modalErrorCheckout')
        })
      }else{
        this.setModal(true, '', 'modalErrorCheckout')
        this.checkoutErrorMessage = 'Silahkan Pilih Produk!'
      }

    },
    setModal(condition, id=null, tipeModal){
      this.idProduct = ''
      this.resetDataModal()
      this.modal.showModal = condition
      this.modalTipe = tipeModal
      if(tipeModal == 'modalConfirmDelete' && condition){
        this.modal.header = true
        this.idProduct = id
        this.modal.footer = true,
        this.modal.footerBtnPosition ='right'
        this.modal.btnCancel = true
        this.modal.btnSubmit = true
        this.modal.btnSubmitText = 'Hapus'
        this.modal.btnCancelText = 'Batal'
        this.modal.btnSubmitWidth = 'auto'
        this.modal.btnCancelWidth = 'auto'
      }else if(tipeModal == 'notifDelete' && condition){
        this.modal.header = true
        this.modal.title = false
        this.modal.footer = true,
        this.modal.footerBtnPosition ='right'
        this.modal.btnSubmit = true
        this.modal.btnSubmitText = 'Oke'
        this.modal.btnSubmitWidth = 'auto'
      }else if(tipeModal == 'modalErrorCheckout' && condition){
        this.modal.footer = true,
        this.modal.btnSubmit = true
        this.modal.btnSubmitText = 'Oke'
        this.modal.footerBtnPosition ='center'
        this.modal.btnSubmitWidth = 'auto'
      }else if(tipeModal == 'modalFormCatatan' && condition){
        this.idProduct = id
        this.modal.header = true,
        this.modal.headerBorder = true,
        this.modal.title = true,
        this.modal.titleText = 'Form Catatan',
        this.modal.footer = true,
        this.modal.footerBorder = true,
        this.modal.btnCancel = true
        this.modal.btnCancelText = 'Batal'
        this.modal.btnSubmit = true
        this.modal.btnSubmitText = 'Simpan'
        this.modal.footerBtnPosition ='right'
        this.modal.btnSubmitWidth = 'auto'
        this.modal.btnCancelWidth = 'auto'
        this.showCatatan(id)
      }
    },
    showCatatan(id){
      let data = this.dataCarts.filter(function (e) {
          return e.id === id
      })

      let note = data[0].note
      this.catatan = note
    },
    resetDataModal(){
      this.modal.showModal = false
      this.modal.header = false,
      this.modal.headerBorder = false
      this.modal.title = false
      this.modal.titleText = '' 
      this.modal.footer = false
      this.modal.footerBtnPosition =''
      this.modal.footerBorder = false
      this.modal.btnCancel = false
      this.modal.btnSubmit = false
      this.modal.btnSubmitText = ''
      this.modal.btnCancelText = ''
      this.modal.btnSubmitWidth = ''
      this.modal.btnCancelWidth = ''
    },
    closeModal(){
      this.setModal(false, '' , this.modalTipe)
      this.checkoutErrorMessage = ''
      if(this.refreshCart){
        this.$store.dispatch('carts')
        this.loading = true
        this.refreshCart = false
      }
    },
    submitModal(){
      this.checkoutErrorMessage = ''
      if(this.modalTipe == 'modalConfirmDelete'){
        this.deleteCart(this.idProduct)
        this.setModal(false, '' , this.modalTipe)
      }else if(this.modalTipe == 'modalErrorCheckout'){
        this.setModal(false, '' , this.modalTipe)
        this.$store.dispatch('carts')
      }else if(this.modalTipe == 'modalFormCatatan'){
        this.updateDataCart(this.idProduct, 'catatan','')
      }
      else{
        this.setModal(false, '' , this.modalTipe)
      }
    },
    updateDataCart(id, tipe, value){
      if((tipe == 'qty' && (value != 0 && value != '' && value != null)) || tipe == 'catatan' ){
        this.setModal(false, '', this.modalTipe)
        let data = new FormData();
        if(tipe == 'catatan'){
          this.loading = true
          data.append('note', this.catatan)
        }else if(tipe == 'qty'){
          data.append('qty', value)
        }
        data.append('_method', 'PUT')
  
        axios.post('/transaction/cart/update-note/'+id, data)
        .then(() => {
          // console.log(res)
          this.catatan = ''
          this.$store.dispatch('carts')
        }).catch(() => {
          // console.log(err)
          // this.errorQty = err.response.data.message
        })
      } else if(value == ''){
        this.setModal(false, '', this.modalTipe)
        let data = new FormData();
        if(tipe == 'qty'){
          data.append('qty', value)
        }
        data.append('_method', 'PUT')

        axios.post('/transaction/cart/update-note/'+id, data)
        .then(() => {
          // console.log(res)
          this.$store.dispatch('carts')
        })
      }
    },

    setProductTerlaris(){
      let data_products = this.dataAllProducts
      let products_terlaris = data_products.sort(function(a, b) {
          return b.total_sold - a.total_sold;
      });
      products_terlaris = products_terlaris.slice(0, 6)
      this.productTerlaris = products_terlaris
    },
    setImgValue(image){
      let data = ''
      if(image.length > 0){
          data = image.filter(function (e) {
              return e.default_image === true
          })

          return data.length > 0 ? data : image
      }else{
        return data
      }
    },
    deleteCart(id){
      this.loading = true
      axios.delete('transaction/cart/delete-cart/'+id)
      .then(() => {
        // console.log(res)
        this.setModal(true, '' , 'notifDelete')
        this.$store.dispatch('carts')
        this.loading = false
        this.dataCartsSelected = []
        this.cart_id = []
        this.totalBarang = 0
        this.totalPrice = 0
      })
      .catch(() => {
        // console.log(err)
      })
    },
    getUser(){
      axios.get(`user`)
      .then(() => {
        // console.log('user: ', res);
          // this.user = res.data.data
      }).catch(() => {
        // console.log(err.response);
        // if (err.response.data.message == 'Unauthenticated.'){
        //   this.$cookies.remove('token')
        //   // this.$router.push('/login')
        // }
      })
    },
    autoLogout(){
      axios.interceptors.response.use( (response) => {
            // Return a successful response back to the calling service
            return response;
        }, (error) => {
            // console.log('response error', error.response)
            if (error.response.status == 401) {
              if (error.response.data.message == "Unauthenticated.") {
                  // Hapus token di cookie
                  this.$cookies.remove('token')
                  this.$store.dispatch('cart')
                  // Redirect Home atau login
                  this.$router.push('/login')
              }
          }

          return Promise.reject(error.response);
        })
    }
  },
  mounted(){
    if(this.$cookies.get('token')){
      this.getUser()
    }
    this.autoLogout()
    if(this.dataAllProducts != ''){
      this.setProductTerlaris()
    }else if(this.dataAllProducts){
      this.setProductTerlaris()
    }
    // console.log(this.dataAllProducts);
    if(this.dataCarts){
      this.loading=false
    }
    // console.log(this.dataAllProducts);
  },
  watch: {
    dataAllProducts:{
      deep: true,
      handler: function(){
        if(this.dataAllProducts != ''){
          this.setProductTerlaris()
        }else if(this.dataAllProducts){
          this.setProductTerlaris()
        }
      }
    },
    dataCarts:{
      deep: true,
      handler: function(){
        if(Array.isArray(this.dataCarts)){
          this.loading = false
        }
      }
    },
  }
}
</script>

