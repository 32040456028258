<template>
    <div class="">
        <div class="container-custom">
            <div class="data-left">
                <img :src="baseUrl+'storage/'+image" alt="">
            </div>
            <div class="data-right">
                <div class="isi-data-right">
                    <router-link to="/" class="d-block text-center font-bold custom-link logo-container auth-logo-container" style="font-size:24px;">
                        <logo></logo>
                        <!-- <img :src="require(`../../assets/DSN-logo.png`)" alt="" class="logo"> -->
                    </router-link>

                    <h2 class="mt-3 text-center">Atur ulang kata sandi Anda</h2>
                    <div class="alert alert-light alert-dismissible fade show border" role="alert">
                        <small><strong>Tips!</strong> kata sandi baru yang berisi sedikitnya 8 karakter. Kata sandi yang kuat adalah kombinasi huruf, angka, dan tanda baca.</small>
                        <button type="button" class="btn-close btn-sm" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>
                    <form @submit.prevent="resetPassword" class="mt-xl-5">
                        <div class="form-group mt-4">
                            <label for="email">Email Anda</label>
                            <input type="text" class="form-control mt-2 radius" id="email" placeholder="Ketik Email Disini" v-model="email"
                             :class="[v$.email.$error ? 'is-invalid' : '']"
                            >
                            <span v-if="v$.email.$error" class="text-danger font-small">{{v$.email.$errors[0].$message}}</span>

                        </div>
                        <div class="form-group mt-4">
                            <label for="password">Kata Sandi Baru</label>
                            <div class="position-relative password-input">
                                <input type="password" class="form-control mt-2 radius" id="password" placeholder="Ketik Kata Sandi Baru Disini" autocomplete="false" v-model="password"
                                :class="[v$.password.$error ? 'is-invalid' : '']"
                                >
                                <div v-if="!v$.password.$error" class="icon-container" @click="togglePass('password')">
                                    <i class='bx bx-hide'></i>
                                </div>
                            </div>
                            <span v-if="v$.password.$error" class="text-danger font-small">{{v$.password.$errors[0].$message}}</span>

                        </div>
                        <div class="form-group mt-4">
                            <label for="password_confirmation">Konfirmasikan Kata Sandi Baru</label>
                            <div class="position-relative password-input">
                                <input type="password" class="form-control mt-2 radius" id="password_confirmation" autocomplete="false" placeholder="Ketik Kata Sandi Baru Disini" v-model="password_confirmation"
                                :class="[v$.password_confirmation.$error ? 'is-invalid' : '']"
                                >
                                <div v-if="!v$.password_confirmation.$error" class="icon-container" @click="togglePass('password_confirmation')">
                                    <i class='bx bx-hide'></i>
                                </div>
                            </div>
                            <span v-if="v$.password_confirmation.$error" class="text-danger font-small">{{v$.password_confirmation.$errors[0].$message}}</span>
                        </div>
                        <div v-if="pesan_sukses != ''" class="alert alert-success alert-dismissible fade show mt-4"  role="alert">
                            <strong>Berhasil ! </strong> 
                            <span>{{pesan_sukses}}</span>
                        </div>
                       <div v-if="error_token != ''" class="alert alert-warning alert-dismissible fade show mt-4"  role="alert">
                            <strong>Peringatan ! </strong> 
                            <span>{{error_token}}</span>
                        </div>
                        <div class="form-group mt-4">
                            <button type="submit" class="btn btn-red w-100 mt-md-3 btn-radius">Atur Ulang</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <div v-if="loadingSubmit" class="loading-submit-animation-container" >
            <div class="loading-submit-animation-content">
                <div>
                    <div class="lds-facebook"><div></div><div></div><div></div></div>
                    <p>Mohon tunggu, data sedang diproses</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    import useVuelidate from '@vuelidate/core'
    import { required, email, helpers, minLength, sameAs   } from '@vuelidate/validators'
    import Logo from "@/components/Logo.vue"
    export default {
        name: 'reset-password-component',
        props: ['banners'],
        setup () {
            return { v$: useVuelidate() }
        },
        validations() {
            return {
                email: {
                    required: helpers.withMessage("Email tidak boleh kosong", required),
                    email: helpers.withMessage("Format email salah", email)
                },
                password: {
                    required: helpers.withMessage("Password tidak boleh kosong", required), 
                    min: helpers.withMessage(
                        ({
                            $params
                        }) => `Password minimal memiliki ${$params.min} karakter `,
                        minLength(8)
                    )
                },
                password_confirmation: {
                    required: helpers.withMessage("Password konfirmasi tidak boleh kosong", required), 
                    sameAs: helpers.withMessage(`Password konfirmasi tidak sama dengan password `,sameAs(this.password))
                },
            }
        },
    components: {
        Logo
    },
    data(){
        return{
            'pesan_error': '',
            'pesan_sukses': '',
            'token': '',
            'email': '',
            'password': '',
            'password_confirmation': '',
            'error_token' : '',
            loadingSubmit: false,
            image: {},
            baseUrl: process.env.VUE_APP_NAME_AXIOS
        }
    },
    mounted(){
        let url_token = window.location.search
        this.token = url_token.substr(7)
        this.setImage()
    },
    methods: {
        resetPassword(){
            this.v$.$validate()
            this.error_token = ''
            this.pesan_sukses = ''
            let data = new FormData()
            data.append('email', this.email)
            data.append('password', this.password)
            data.append('password_confirmation', this.password_confirmation)
            data.append('token', this.token)
            if ( !this.v$.$error) {
                this.loadingSubmit =  true

                axios.post('customer/reset-password', data)
                .then(response => {
                    this.pesan_sukses = response.data.status
                    this.$router.push('/login')
                    this.loadingSubmit =  false

                })
                .catch(error => {
                    this.error_token = error.response.data.message;
                    this.loadingSubmit =  false

                })
            }
        
        },
        togglePass(id){
            if(document.querySelector('#'+id).type == 'password'){
                document.querySelector('#'+id).type = 'text'
                document.querySelector('#'+id+'~.icon-container .bx').classList.remove('bx-hide')
                document.querySelector('#'+id+'~.icon-container .bx').classList.add('bx-show')
            }else {
                document.querySelector('#'+id).type = 'password'
                document.querySelector('#'+id+'~.icon-container .bx').classList.remove('bx-show')
                document.querySelector('#'+id+'~.icon-container .bx').classList.add('bx-hide')
            }
        },
        setImage(){
            if(this.banners != null){
                for(let i = 0; i < Object.keys(this.banners).length; i++){
                    if(this.banners[i].position === 'Reset Password Page'){
                        this.image = this.banners[i].image
                        // console.log(this.baseUrl+this.loginImage);
                    }
                }
                this.loadingMainBanner = false
            }
        },
    },
    watch:{
        banners:{
            deep: true,
            handler: function(){
                this.setImage()
                //           :autoplay='{
                // "delay": 2500,
                // "disableOnInteraction": false
                // }'
            }
        },
    }
    }
</script>

<style scoped>
.container-custom{
    display: flex;
    flex-direction: column;
}
.password-input .icon-container{
    position:  absolute;
    top:0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items:center;
    padding: 1rem;
}

.password-input .icon-container:hover{
    cursor:pointer;
}

.password-input input[type="password"]{
    padding-right: 2.5rem;
}
.data-left{
    height: auto;
    position: relative;
    display: none;

}
.data-left img{
    object-fit: cover;
    width: 100%;
    height: 100%;
}
.data-left::after{
    content:'';
    width: 100%;
    min-height: 100vh;
    height: 100%;
    /* background-image: url("../../assets/img/abby-anaday-Z3fXPuxa15k-unsplash.webp"); */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    position: fixed;
    top:0;
    left: 0;
}
.data-right{
    min-height: 100vh;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding:70px 20px;
    position: relative;
    background: white;
}

.data-left,
.data-right{
    width: 100%;
}

.isi-data-right small{
    color: var(--color-light);
}
.radius{
    border-radius: 25px;
    padding: 0.7rem 1.5rem
}
.btn-radius{
    border-radius: 25px;
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
}
@media (min-width: 319px) {
}

@media (min-width: 374px) {
}

@media (min-width: 424px) {
}

@media (min-width: 767px) {
    .container-custom{
        flex-direction: row;
    }
    .data-left,
    .data-right{
        width: 50%;
    }

    .data-left{
        display: block;
    }
}

@media (min-width: 1023px) {
}

@media (min-width: 1439px) {
}

@media (min-width: 2000px) {
}
</style>